import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import { useUser } from "./UserContext";
import { apiUrl } from "./apiConfig";
import useFullPageLoader from "../mui/useFullPageLoader";

const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { setData } = useUser();
  const [userData, setUserData] = useState(null);
  axios.defaults.withCredentials = true;
  useEffect(() => {
    showLoader();
    axios.get(`${apiUrl}/dashboard`).then((res) => {
      if (res.data.Status === "Success") {
        const userData = {
          userId: res.data.UserId,
          role: res.data.Role,
          firstName: res.data.First_Name,
          lastName: res.data.Last_Name,
          email: res.data.Email,
        };
        hideLoader();
        setUserData(userData);
        setData(userData);
      } else {
        navigate("/login");
      }
    });
  }, []);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    axios
      .get(`${apiUrl}/logout`)
      .then((res) => {
        // Clear user data from state and navigate to the login page
        setUserData(null); // Assuming you have a state variable for userData
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <div className="navbar-custom">
          <div className="topbar container-fluid">
            <div className="d-flex align-items-center gap-lg-2 gap-1">
              <div className="logo-topbar">
                <a href="/" className="logo-light">
                  <span className="logo-lg">
                    <img src="/assets/images/logo.png" alt="logo" />
                  </span>
                  <span className="logo-sm">
                    <img src="/assets/images/logo-sm.png" alt="small logo" />
                  </span>
                </a>

                <a href="/" className="logo-dark">
                  <span className="logo-lg">
                    <img src="/assets/images/logo-dark.png" alt="dark logo" />
                  </span>
                  <span className="logo-sm">
                    <img src="/assets/images/logo-sm.png" alt="small logo" />
                  </span>
                </a>
              </div>
            </div>

            <ul className="topbar-menu d-flex align-items-center gap-3">
              <li className="dropdown me-md-2">
                <a
                  className="nav-link dropdown-toggle arrow-none nav-user px-2"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <span className="account-user-avatar">
                    <img
                      src="/assets/images/users/avatar-1.jpg"
                      alt="user-image"
                      width="32"
                      className="rounded-circle"
                    />
                  </span>
                  <span className="d-lg-flex flex-column gap-1 d-none">
                    <h5 className="my-0">
                      {userData?.firstName} {userData?.lastName}
                    </h5>
                    {/* <h6 className="my-0 fw-normal">Founder</h6> */}
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                  <div className=" dropdown-header noti-title">
                    <h6 className="text-overflow m-0">Welcome !</h6>
                  </div>

                  <div>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={handleOpenChangePasswordModal}
                    >
                      <i className="ri-key-fill align-middle me-1"></i>
                      <span>Change Password</span>
                    </Link>

                    <ChangePasswordModal
                      open={isChangePasswordModalOpen}
                      onClose={handleCloseChangePasswordModal}
                      UserId={userData?.userId}
                    />
                  </div>

                  <Link
                      to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="ri-logout-box-fill align-middle me-1"></i>
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-page">
          <div className="content">
            <Outlet />

            <div className="container-fluid"></div>
          </div>
        </div>

        <div className="leftside-menu menuitem-active">
          <div
            className="button-sm-hover"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            aria-label="Show Full Sidebar"
            data-bs-original-title="Show Full Sidebar"
          >
            <i className="ri-checkbox-blank-circle-line align-middle"></i>
          </div>

          <div className="button-close-fullsidebar">
            <i className="ri-close-fill align-middle"></i>
          </div>

          <div
            className="h-100 show"
            id="leftside-menu-container"
            data-simplebar="init"
          >
            <div className="simplebar-wrapper" style={{ margin: "0px" }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div
                className="simplebar-mask"
                style={{ right: "0px", bottom: "0px" }}
              >
                <div
                  className="simplebar-offset"
                  style={{ right: "0px", bottom: "0px" }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    tabIndex="0"
                    role="region"
                    aria-label="scrollable content"
                    style={{ height: "100%", overflow: "hidden scroll" }}
                  >
                    <div
                      className="simplebar-content"
                      style={{ padding: "0px" }}
                    >
                      <div className="leftbar-user p-3 text-white"></div>

                      <ul className="side-nav">
                        <li className="side-nav-title mt-1">Main</li>

                        <li className="side-nav-item ">
                          <Link to="/" className="side-nav-link ">
                            <i className="ri-dashboard-2-fill"></i>
                            <span className="badge bg-success float-end">
                              9+
                            </span>
                            <span> Dashboard </span>
                          </Link>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sidebarEmail"
                            aria-expanded="false"
                            aria-controls="sidebarEmail"
                            className="side-nav-link"
                          >
                            <i className="ri-group-fill"></i>
                            <span> Manage Staff </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sidebarEmail">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/addStaff">
                                  {" "}
                                  <span> Add Staff </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewStaff">
                                  {" "}
                                  <span> View Staff </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sidebarTasks"
                            aria-expanded="false"
                            aria-controls="sidebarTasks"
                            className="side-nav-link"
                          >
                            <i className="ri-user-settings-fill"></i>
                            <span> Manage Client </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sidebarTasks">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/addClient">
                                  {" "}
                                  <span> Add Client </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewClient">
                                  {" "}
                                  <span> View Client </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sidebarVenders"
                            aria-expanded="false"
                            aria-controls="sidebarVenders"
                            className="side-nav-link"
                          >
                            <i className="ri-team-fill"></i>
                            <span>Manage Vendor </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sidebarVenders">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/addVender">
                                  {" "}
                                  <span> Add Vendor</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewVender">
                                  {" "}
                                  <span> View Vendor </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sidebarBills"
                            aria-expanded="false"
                            aria-controls="sidebarBills"
                            className="side-nav-link"
                          >
                            <i className="ri-bill-fill"></i>
                            <span>Manage Expense </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sidebarBills">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/createBill">
                                  {" "}
                                  <span> Bill Entry </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewBill">
                                  {" "}
                                  <span> View Expense </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sideVisit"
                            aria-expanded="false"
                            aria-controls="sidebarEmail"
                            className="side-nav-link"
                          >
                            <i className="ri-map-pin-user-fill"></i>

                            <span> Daily Visit </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sideVisit">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/addVisit">
                                  {" "}
                                  <span>Assign Visit</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewVisit">
                                  {" "}
                                  <span>View Visit</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sideTarget"
                            aria-expanded="false"
                            aria-controls="sidebarEmail"
                            className="side-nav-link"
                          >
                            <i class="ri-focus-2-fill"></i>

                            <span> Manage Target</span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sideTarget">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/setTarget">
                                  {" "}
                                  <span>Set Target</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewTarget">
                                  {" "}
                                  <span>View Target</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sideOrder"
                            aria-expanded="false"
                            aria-controls="sidebarEmail"
                            className="side-nav-link"
                          >
                            <i class="ri-shopping-cart-2-fill"></i>
                            <span> Orders </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sideOrder">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/order">
                                  {" "}
                                  <span>Create  Order</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewOrder">
                                  {" "}
                                  <span>View Orders</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sideReport"
                            aria-expanded="false"
                            aria-controls="sidebarEmail"
                            className="side-nav-link"
                          >
                            <i className="ri-folder-chart-fill"></i>
                            <span> Reports </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sideReport">
                            <ul className="side-nav-second-level">
                              <li>
                                <Link to="/proforma">
                                  {" "}
                                  <span>Create Proforma</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/viewProforma">
                                  {" "}
                                  <span>View Proforma</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#sidebarSettings"
                            aria-expanded="false"
                            aria-controls="sidebarSettings"
                            className="side-nav-link"
                          >
                            <i className="ri-settings-4-fill"></i>
                            <span> Settings </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="sidebarSettings">
                            <ul className="side-nav-second-level">
                            <li>
                                <Link to="/addProduct">
                                  {" "}
                                  <span>Add Product</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/productCategory">
                                  {" "}
                                  <span>Product Category</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/unitOfMeasurement">
                                  {" "}
                                  <span>Unit Of Measurement</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/expenses">
                                  {" "}
                                  <span>Expenses Category</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/orgCategory">
                                  {" "}
                                  <span>Org Category</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/remarks">
                                  {" "}
                                  <span>Remarks</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default Dashboard;
