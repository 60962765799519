import "./client.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../componets/UserContext";
import useButtonLoader from "../../mui/useButtonLoader";

const AddClient = () => {
  const [buttonElement, setLoading] = useButtonLoader(
    "Add Client",
    "Add Client..."
  );
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const { userData } = useUser();
  const [invoices, setInvoices] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [IspreviousInv, setIspreviousInv] = useState(false);

  //fetching last invoice number
  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getClientId`);
      setInvoices(result.data);
      setIspreviousInv(true);
    })();
  }, []);

  useEffect(() => {
    // Generate the next invoice number based on existing data
    if (IspreviousInv) {
      const nextInvoiceNumber = generateNextInvoiceNumber(invoices);
      setInvoiceNumber(nextInvoiceNumber);
    }
  }, [IspreviousInv]);

  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices.length === 0) {
      return "CLIENT-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `CLIENT-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
    setClient({ ...client, GST_NO: value });
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [CategoryId, setCategoryId] = useState(null);

  useEffect(() => {
    loadCategory();
  }, []);

  const loadCategory = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getOrgCategories`);
      const category = response.data;
      const Category = category.map((cat) => ({
        label: cat.Category_Name,
        value: cat.Sr_No,
      }));
      setCategoryOption(Category);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    }
  };

  const [client, setClient] = useState({
    Client_Id: "",
    Client_Cat_Id: "",
    Client_Cat: "",
    Company_Name: "",
    Owner_Name: "",
    Mobile_No: "",
    Alt_Mobile_No: "",
    Email: "",
    GST_NO: "",
    PinCode: "",
    Address: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedClient = {
      ...client,

      Client_Id: invoiceNumber,
      Client_Cat_Id: CategoryId,
      Client_Cat: CategoryName,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    // Perform validation here
    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(client.Owner_Name)) {
      validationErrors.Owner_Name = "Invalid Input";
    }

    if (!/^([6-9][0-9]{9,11})$/.test(client.Mobile_No)) {
      validationErrors.Mobile_No = "Invalid Mobile Number";
    }

    if (
      client.Alt_Mobile_No &&
      !/^([6-9][0-9]{9,11})$/.test(client.Alt_Mobile_No)
    ) {
      validationErrors.Alt_Mobile_No = "Invalid Alternate Mobile Number ";
    }
    if (client.GST_NO && client.GST_NO.length !== 15) {
      validationErrors.GST_NO = "GST No must be 15 characters";
    }
    if (client.PinCode && !/^\d{6}$/.test(client.PinCode)) {
      validationErrors.PinCode = "Pin Code must be 6 digits";
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/addClient`, updatedClient)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Your Client Record has been Added.",
            "success"
          );
          navigate("/viewClient");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryName(selectedOption.label);
      setCategoryId(selectedOption.value);
    } else {
      setCategoryName("");
      setCategoryId(null);
    }
    setSelectedCategory(selectedOption);
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">Add Client</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Client_Id" className="form-label">
                      Client ID*
                    </label>
                    <input
                      type="text"
                      name="Client_Id"
                      className="form-control"
                      id="Client_Id"
                      placeholder="Enter Client ID"
                      disabled
                      value={invoiceNumber}
                      onChange={setInvoiceNumber}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Client_Cat" className="form-label">
                      Org Category*
                    </label>
                    <Select
                      type="text"
                      name="Client_Cat"
                      required
                      id="Client_Cat"
                      value={selectedCategory}
                      options={categoryOption}
                      onChange={handleCategoryChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Company_Name" className="form-label">
                      Company Name*
                    </label>
                    <input
                      type="text"
                      name="Company_Name"
                      className={`form-control ${
                        errors.Company_Name ? "is-invalid" : ""
                      }`}
                      id="Company_Name"
                      required
                      placeholder="Enter Company Name"
                      onChange={(e) =>
                        setClient({ ...client, Company_Name: e.target.value })
                      }
                    />
                    {errors.Company_Name && (
                      <div className="invalid-feedback">
                        {errors.Company_Name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Owner_Name" className="form-label">
                        Owner Name*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.Owner_Name ? "is-invalid" : ""
                        }`}
                        id="Owner_Name"
                        name="Owner_Name"
                        placeholder="Enter Owner Name"
                        required
                        onChange={(e) =>
                          setClient({ ...client, Owner_Name: e.target.value })
                        }
                      />
                      {errors.Owner_Name && (
                        <div className="invalid-feedback">
                          {errors.Owner_Name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Mobile_No" className="form-label">
                        Mobile*
                      </label>
                      <input
                        type="text"
                        name="Mobile_No"
                        className={`form-control ${
                          errors.Mobile_No ? "is-invalid" : ""
                        }`}
                        id="Mobile_No"
                        required
                        maxLength="10"
                        value={client.Mobile_No}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setClient({ ...client, Mobile_No: numericInput });
                        }}
                        placeholder="Enter Mobile Number"
                      />
                      {errors.Mobile_No && (
                        <div className="invalid-feedback">
                          {errors.Mobile_No}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Alt_Mobile_No" className="form-label">
                        Alternate Mobile
                      </label>
                      <input
                        type="text"
                        name="Alt_Mobile_No"
                        className={`form-control ${
                          errors.Alt_Mobile_No ? "is-invalid" : ""
                        }`}
                        id="Alt_Mobile_No"
                        value={client.Alt_Mobile_No}
                        maxLength="10"
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setClient({
                            ...client,
                            Alt_Mobile_No: numericInput,
                          });
                        }}
                        placeholder="Enter Alt Mobile No"
                      />
                      {errors.Alt_Mobile_No && (
                        <div className="invalid-feedback">
                          {errors.Alt_Mobile_No}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        name="Email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Enter Email"
                        onChange={(e) =>
                          setClient({ ...client, Email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="GSTNo" className="form-label">
                        GST No
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.GST_NO ? "is-invalid" : ""
                        }`}
                        id="GSTNo"
                        name="GST_NO"
                        placeholder="Enter GST No"
                        autoComplete="off"
                        maxLength="15"
                        value={inputValue}
                        onChange={handleInput}
                      />
                      {errors.GST_NO && (
                        <div className="invalid-feedback">{errors.GST_NO}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="PinCode" className="form-label">
                        Pin Code*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.PinCode ? "is-invalid" : ""
                        }`}
                        name="PinCode"
                        id="PinCode"
                        keyboardType="numeric"
                        placeholder="Enter Pin Code"
                        autoComplete="off"
                        required
                        maxLength="6"
                        onChange={(e) =>
                          setClient({ ...client, PinCode: e.target.value })
                        }
                      />
                      {errors.PinCode && (
                        <div className="invalid-feedback">{errors.PinCode}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="Address" className="form-label">
                        Address*
                      </label>
                      <textarea
                        className="form-control"
                        id="Address"
                        name="Address"
                        placeholder="Address"
                        required
                        onChange={(e) =>
                          setClient({ ...client, Address: e.target.value })
                        }
                        multiline="true"
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      ref={buttonElement}
                    >
                      Add Client
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
