import React, { useState, useEffect } from "react";
import axios from "axios";
import "flatpickr/dist/themes/material_green.css";
import { apiUrl } from "../../componets/apiConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MySelect from "../../mui/MySelect.jsx";
import MultiSelect from "../../mui/MultiSelect.jsx";
import RadioButton from "../../mui/Radio.js";
import { useUser } from "../../componets/UserContext";

import {
  Container,
  Stepper,
  Step,
  TextareaAutosize,
  TextField,
  StepLabel,
  CircularProgress,
  Button,
  StepIcon,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import step1Image from "../../assets/step1.png";
import step2Image from "../../assets/step2.png";
import step3Image from "../../assets/step3.png";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";

/* above all Impots */

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: 1,
  color: "#fff",
  width: 60,
  height: 60,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
  }),
}));

const CustomStepIcon = (props) => {
  const { active, completed, icon, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Check style={{ fontSize: 32, color: "#fff" }} />
      ) : (
        <div>
          <img
            src={icon}
            alt={`Step ${props.icon}`}
            style={{ width: "47px" }}
          />
          <StepIcon
            {...props}
            completed={completed}
            active={active}
            icon={null}
          />
        </div>
      )}
    </ColorlibStepIconRoot>
  );
};

const options = [
  { label: "--Select--", value: "-1", disabled: true },
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const Punches = [
  { label: "--Select--", value: "-1", disabled: true },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const YesNO = [
  { label: "--Select--", value: "-1", disabled: true },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const LateHalfDay = [
  { label: "--Select--", value: "-1", disabled: true },
  { label: "Total Working Hours", value: "Total Working Hours" },
  { label: "Shift Timing", value: "Shift Timing" },
];

const PayType = [
  { label: "--Select--", value: "-1", disabled: true },
  { label: "Fixed", value: "Fixed" },
  { label: "Hourly", value: "Hourly" },
];

const roleOptions = [
  { label: "Back Office", value: "Back Office" },
  { label: "Sales Person", value: "Sales Person" },
];

const steps = ["Personal Details", "Payroll", "Document"];

const NineAM = dayjs().set("hour", 9).set("minute", 0).set("second", 0);
const SixPM = dayjs().set("hour", 18).set("minute", 0).set("second", 0);

const AddStaff = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { userData } = useUser();
  const [isLoading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [isPayrollApplicable, setIsPayrollApplicable] = useState(true);
  const [selectedRole, setSelectedRole] = useState(["Back Office"]);
  const [SecondShiftDay, setSecondShiftValues] = useState([]);
  const [SinglePunches, setSinglePunchValues] = useState(false);
  const [selectedNames, setSelectedNames] = useState(["Sunday"]);
  const [selectedLateHalf, setLateHalf] = useState(["Total Working Hours"]);
  const [selectPayType, setSelectPayType] = useState(["Hourly"]);
  const [selectedOvertime, setOvertime] = useState(["No"]);
  const [selectedDeductions, setDeductions] = useState(["No"]);
  const [shift1InTime, setshift1InTime] = useState(NineAM);
  const [shift1OutTime, setshift1OutTime] = useState(SixPM);
  const [shift2InTime, setshift2InTime] = useState(null);
  const [shift2OutTime, setshift2OutTime] = useState(null);
  const [step1ValidationErrors, setStep1ValidationErrors] = useState({});
  const [step2ValidationErrors, setStep2ValidationErrors] = useState({});
  const [step3ValidationErrors, setStep3ValidationErrors] = useState({});

  const [inputValue, setInputValue] = useState("");

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);

    setEmployee({ ...employee, Pan_No: value });
  };

  const validateStep1Fields = () => {
    const errors = {};
    if (!/^[a-zA-Z]+$/.test(employee.First_Name)) {
      errors.First_Name = "Invalid Input";
    }

    if (!/^[a-zA-Z]+$/.test(employee.Last_Name)) {
      errors.Last_Name = "Invalid Input";
    }

    if (!/^([6-9][0-9]{9,11})$/.test(employee.Mobile_No)) {
      errors.Mobile_No = "Invalid Mobile Number";
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      setStep1ValidationErrors(errors);
      return false;
    }
  };

  const validateStep2Fields = () => {
    const errors = {};

    if (isPayrollApplicable) {
      if (!employee.Amount) {
        errors.Amount = "Enter Salary Amount";
      }
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      setStep2ValidationErrors(errors);
      return false;
    }
  };

  const validateStep3Fields = () => {
    const errors = {};
    if (employee.Aadhar_No && employee.Aadhar_No.length !== 12) {
      errors.Aadhar_No = "Aadhar No must be 12 characters";
    }

    if (employee.Pan_No && employee.Pan_No.length !== 10) {
      errors.Pan_No = "Pan No must be 10 characters";
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      setStep3ValidationErrors(errors);
      return false;
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const isValidStep1 = validateStep1Fields();

      if (isValidStep1) {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      const isValidStep2 = validateStep2Fields();

      if (isValidStep2) {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setStep1ValidationErrors({});
    }
    if (activeStep === 2) {
      setStep2ValidationErrors({});
    }
    setActiveStep(activeStep - 1);
  };

  const handleshift1InTime = (newTime) => {
    setshift1InTime(newTime);
  };

  const handleshift1OutTime = (newTime) => {
    setshift1OutTime(newTime);
  };
  const handleshift2InTime = (newTime) => {
    setshift2InTime(newTime);
  };
  const handleshift2OutTime = (newTime) => {
    setshift2OutTime(newTime);
  };

  const handleRoleChange = (event) => {
    const newSelectedRole = event.target.value;
    setSelectedRole(newSelectedRole);
    if (newSelectedRole === "Back Office") {
      setIsPayrollApplicable(true);
    } else if (newSelectedRole === "Sales Person") {
      setIsPayrollApplicable(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/newBiometricId`)
      .then((res) => {
        const nextBiometricId = res.data.maxBiometricId;
        setEmployee((prevEmployee) => ({
          ...prevEmployee,
          UUID: nextBiometricId,
        }));
      })
      .catch((err) => console.log(err));
  }, []);

  const [invoices, setInvoices] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [IspreviousInv, setIspreviousInv] = useState(false);
  //fetching last invoice number
  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getStaffId`);
      setInvoices(result.data);
      setIspreviousInv(true);
    })();
  }, []);

  useEffect(() => {
    // Generate the next invoice number based on existing data
    if (IspreviousInv) {
      const nextInvoiceNumber = generateNextInvoiceNumber(invoices);
      setInvoiceNumber(nextInvoiceNumber);
    }
  }, [IspreviousInv]);

  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices.length === 0) {
      return "STAFF-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `STAFF-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }

  const [employee, setEmployee] = useState({
    UUID: "",
    Staff_Id: "",
    First_Name: "",
    Last_Name: "",
    Mobile_No: "",
    Pan_No: "",
    Pan_Doc: "",
    Aadhar_No: "",
    Aadhar_Doc: "",
    Address: "",
    Payroll: "",
    SecondShiftDay: "",
    WeekOff: "",
    SinglePunch: "",
    LateHalf: "",
    PayType: "",
    Role: "",
    Overtime_Consideration: "",
    Deductions: "",
    MF_InTime: "",
    MF_OutTime: "",
    Sat_InTime: "",
    Sat_OutTime: "",
    Username: "",
    Password: "",
    Status: "",
    Amount: "",
    Added_By: "",
    Added_By_Id: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;

    if (activeStep === 0) {
      const isValidStep1 = validateStep1Fields();

      if (!isValidStep1) {
        return;
      }
    } else if (activeStep === 1) {
      const isValidStep2 = validateStep2Fields();

      if (!isValidStep2) {
        return;
      }
    } else if (activeStep === 2) {
      const isValidStep3 = validateStep3Fields();

      if (!isValidStep3) {
        return;
      }
    }

    const updatedEmployee = {
      ...employee,
      Staff_Id: invoiceNumber,
      Payroll: isPayrollApplicable,
      SecondShiftDay: SecondShiftDay,
      WeekOff: selectedNames,
      SinglePunch: SinglePunches,
      LateHalf: selectedLateHalf,
      PayType: selectPayType,
      Overtime_Consideration: selectedOvertime,
      Deductions: selectedDeductions,
      MF_InTime: shift1InTime,
      MF_OutTime: shift1OutTime,
      Sat_InTime: shift2InTime,
      Sat_OutTime: shift2OutTime,
      Role: selectedRole,
      Username: employee.Mobile_No,
      Password: "staff@123",
      Status: 1,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const formdata = new FormData();
    formdata.append("UUID", employee.UUID);
    formdata.append("Staff_Id", updatedEmployee.Staff_Id);
    formdata.append("First_Name", employee.First_Name);
    formdata.append("Last_Name", employee.Last_Name);
    formdata.append("Mobile_No", employee.Mobile_No);
    formdata.append("Address", employee.Address);
    formdata.append("Amount", employee.Amount);
    formdata.append("Payroll", updatedEmployee.Payroll);
    formdata.append("Role", updatedEmployee.Role);
    formdata.append("Status", updatedEmployee.Status);
    formdata.append("Username", updatedEmployee.Username);
    formdata.append("Password", updatedEmployee.Password);
    formdata.append("SecondShiftDay", updatedEmployee.SecondShiftDay);
    formdata.append("WeekOff", updatedEmployee.WeekOff);
    formdata.append("SinglePunch", updatedEmployee.SinglePunch);
    formdata.append("LateHalf", updatedEmployee.LateHalf);
    formdata.append("PayType", updatedEmployee.PayType);
    formdata.append(
      "Overtime_Consideration",
      updatedEmployee.Overtime_Consideration
    );
    formdata.append("Deductions", updatedEmployee.Deductions);
    formdata.append("MF_InTime", updatedEmployee.MF_InTime);
    formdata.append("MF_OutTime", updatedEmployee.MF_OutTime);
    formdata.append("Sat_InTime", updatedEmployee.Sat_InTime);
    formdata.append("Sat_OutTime", updatedEmployee.Sat_OutTime);
    formdata.append("Pan_No", employee.Pan_No);
    formdata.append("Pan_Doc", employee.Pan_Doc);
    formdata.append("Aadhar_No", employee.Aadhar_No);
    formdata.append("Aadhar_Doc", employee.Aadhar_Doc);
    formdata.append("Added_By_Id", updatedEmployee.Added_By_Id);
    formdata.append("Added_By", updatedEmployee.Added_By);

    const errors = {};

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/addStaff`, formdata)
        .then((res) => {
          Swal.fire("Success!", "Your Staff Record has been Added.", "success");
          navigate("/viewStaff");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">Add Staff</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Container className="">
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<ColorlibConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      StepIconProps={{
                        icon:
                          index === 0
                            ? step1Image
                            : index === 1
                            ? step2Image
                            : step3Image,
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <br />
              <Divider />

              <form className="mt-4">
                {activeStep === 0 && (
                  <div className="form">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="UUID" className="form-label">
                            UUID*
                          </label>
                          <TextField
                            type="text"
                            InputProps={{ style: { height: "38px" } }}
                            className="form-control"
                            id="UUID"
                            placeholder="Enter UUID"
                            value={employee.UUID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Staff_Id" className="form-label">
                            Staff ID*
                          </label>
                          <TextField
                            type="text"
                            className="form-control"
                            id="Staff_Id"
                            InputProps={{ style: { height: "38px" } }}
                            placeholder="Enter Staff ID"
                            autoComplete="off"
                            value={invoiceNumber}
                            onChange={setInvoiceNumber}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="First_Name" className="form-label">
                            First Name*
                          </label>
                          <TextField
                            className="form-control"
                            InputProps={{ style: { height: "38px" } }}
                            type="text"
                            id="First_Name"
                            required
                            //autoComplete="off"
                            placeholder="Enter First Name"
                            value={employee.First_Name}
                            onChange={(e) =>
                              setEmployee({
                                ...employee,
                                First_Name: e.target.value,
                              })
                            }
                            error={Boolean(step1ValidationErrors.First_Name)}
                            helperText={step1ValidationErrors.First_Name}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Last_Name" className="form-label">
                            Last Name*
                          </label>
                          <TextField
                            type="text"
                            className="form-control"
                            id="Last_Name"
                            placeholder="Enter Last Name"
                            value={employee.Last_Name}
                            required
                            InputProps={{ style: { height: "38px" } }}
                            onChange={(e) =>
                              setEmployee({
                                ...employee,
                                Last_Name: e.target.value,
                              })
                            }
                            error={Boolean(step1ValidationErrors.Last_Name)}
                            helperText={step1ValidationErrors.Last_Name}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Mobile_No" className="form-label">
                            Mobile*
                          </label>
                          <TextField
                            className="form-control"
                            InputProps={{ style: { height: "38px" } }}
                            type="text"
                            id="Mobile_No"
                            required
                            value={employee.Mobile_No}
                            placeholder="Enter Mobile Number"
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) => {
                              const input = e.target.value;
                              const numericInput = input.replace(/[^0-9]/g, "");
                              setEmployee({
                                ...employee,
                                Mobile_No: numericInput,
                              });
                            }}
                            error={Boolean(step1ValidationErrors.Mobile_No)}
                            helperText={step1ValidationErrors.Mobile_No}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="Address" className="form-label">
                            Address
                          </label>
                          <TextareaAutosize
                            className="form-control"
                            id="Address"
                            placeholder="Address"
                            value={employee.Address}
                            sx={{ height: "60px" }}
                            multiline="true"
                            rows={4}
                            onChange={(e) =>
                              setEmployee({
                                ...employee,
                                Address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeStep === 1 && (
                  <div className="row">
                    <div className="col-4">
                      <div className="mb-3">
                        <h5 htmlFor="Role" className="form-label">
                          {" "}
                          Select Role*
                        </h5>
                        <RadioButton
                          id="Role"
                          options={roleOptions}
                          selectedValue={selectedRole}
                          onChange={handleRoleChange}
                        />
                      </div>
                    </div>

                    {isPayrollApplicable && (
                      <div className="row">
                        <div className="col-3">
                          <div className="mb-3">
                            <label htmlFor="simpleinput" className="form-label">
                              Shift1 InTime*
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                slotProps={{ textField: { size: "small" } }}
                                value={shift1InTime}
                                onChange={handleshift1InTime}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="mb-3">
                            <label htmlFor="simpleinput" className="form-label">
                              Shift1 OutTime*
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                slotProps={{ textField: { size: "small" } }}
                                value={shift1OutTime}
                                onChange={handleshift1OutTime}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="mb-3">
                            <label htmlFor="simpleinput" className="form-label">
                              Shift2 InTime
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                slotProps={{ textField: { size: "small" } }}
                                value={shift2InTime}
                                onChange={handleshift2InTime}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="mb-3">
                            <label htmlFor="simpleinput" className="form-label">
                              Shift2 OutTime
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                slotProps={{ textField: { size: "small" } }}
                                value={shift2OutTime}
                                onChange={handleshift2OutTime}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="SecondShiftDay"
                                className="form-label"
                              >
                                Second Shift Day
                              </label>

                              <MultiSelect
                                id="SecondShiftDay"
                                placeholder="--Select--"
                                options={options}
                                value={SecondShiftDay}
                                onChange={(e) =>
                                  setSecondShiftValues(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="simpleinput"
                                className="form-label"
                              >
                                WeekOff
                              </label>

                              <MultiSelect
                                options={options}
                                value={selectedNames}
                                onChange={(e) =>
                                  setSelectedNames(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="SinglePunches"
                                className="form-label"
                              >
                                Single Punches For The Day*
                              </label>

                              <MySelect
                                options={Punches}
                                value={SinglePunches}
                                onChange={(e) =>
                                  setSinglePunchValues(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="simpleinput"
                                className="form-label "
                              >
                                Late/HalfDay*
                              </label>

                              <MySelect
                                options={LateHalfDay}
                                value={selectedLateHalf}
                                onChange={(e) => setLateHalf(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="selectPayType"
                                className="form-label"
                              >
                                Pay Type*
                              </label>

                              <MySelect
                                options={PayType}
                                value={selectPayType}
                                onChange={(e) =>
                                  setSelectPayType(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="Amount">
                                Salary*
                              </label>
                              <TextField
                                type="text"
                                name="Amount"
                                InputProps={{ style: { height: "38px" } }}
                                className="form-control"
                                id="Amount"
                                placeholder="Enter Salary Amount"
                                value={employee.Amount}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const numericInput =
                                    input.match(/^\d*\.?\d*/)[0];
                                  setEmployee({
                                    ...employee,
                                    Amount: numericInput,
                                  });
                                }}
                                error={Boolean(step2ValidationErrors.Amount)}
                                helperText={step2ValidationErrors.Amount}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="selectedOvertime"
                                  className="form-label"
                                >
                                  Overtime Consideration
                                </label>

                                <MySelect
                                  options={YesNO}
                                  value={selectedOvertime}
                                  onChange={(e) => setOvertime(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="selectedDeductions"
                                  className="form-label"
                                >
                                  Deductions
                                </label>

                                <MySelect
                                  name="selectedDeductions"
                                  id="selectedDeductions"
                                  options={YesNO}
                                  value={selectedDeductions}
                                  onChange={(e) =>
                                    setDeductions(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {activeStep === 2 && (
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Aadhar_No">
                            AdharCard No
                          </label>
                          <TextField
                            InputProps={{ style: { height: "38px" } }}
                            type="text"
                            name="Aadhar_No"
                            className="form-control"
                            id="Aadhar_No"
                            value={employee.Aadhar_No}
                            inputProps={{ maxLength: 12 }}
                            onChange={(e) => {
                              const input = e.target.value;
                              const numericInput = input.replace(/[^0-9]/g, "");
                              setEmployee({
                                ...employee,
                                Aadhar_No: numericInput,
                              });
                            }}
                            error={Boolean(step3ValidationErrors.Aadhar_No)}
                            helperText={step3ValidationErrors.Aadhar_No}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Aadhar_Doc">
                            Select AdharCard
                          </label>
                          <input
                            type="file"
                            name="Aadhar_Doc"
                            className="form-control"
                            id="Aadhar_Doc"
                            onChange={(e) =>
                              setEmployee({
                                ...employee,
                                Aadhar_Doc: e.target.files[0],
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Pan_No">
                            PanCard No
                          </label>
                          <TextField
                            InputProps={{ style: { height: "38px" } }}
                            type="text"
                            name="Pan_No"
                            className="form-control"
                            id="Pan_No"
                            value={inputValue}
                            onInput={handleInput}
                            inputProps={{ maxLength: 10 }}
                            error={Boolean(step3ValidationErrors.Pan_No)}
                            helperText={step3ValidationErrors.Pan_No}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Pan_Doc">
                            Select PanCard
                          </label>
                          <input
                            type="file"
                            name="Pan_Doc"
                            className="form-control"
                            id="Pan_Doc"
                            onChange={(e) =>
                              setEmployee({
                                ...employee,
                                Pan_Doc: e.target.files[0],
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {activeStep !== 0 && (
                    <Button variant="outlined" onClick={handleBack}>
                      <ArrowBackIcon /> Back
                    </Button>
                  )}

                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginLeft: "auto" }}
                    >
                      Next <ArrowForwardIcon />
                    </Button>
                  )}

                  {activeStep === steps.length - 1 &&
                    (isLoading ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        onClick={handleSubmit}
                      >
                        <CircularProgress size={21} color="inherit" />
                        {"  "} Submit...
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    ))}
                </div>
              </form>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
