import * as React from "react";
import * as XLSX from "xlsx";
import { apiUrl } from "../../componets/apiConfig";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";

export default function ViewClient() {
  const [page, setPage] = useState(0);
  const [uniqueCategory, setUniqueCategory] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    (async () => {
      Load();
    })();
  }, []);

  async function Load() {
    const result = await axios.get(`${apiUrl}/getClient`);
    const category = Array.from(
      new Set(result.data.map((row) => row.Client_Cat))
    );
    setUniqueCategory(category);
    setData(result.data);
    setRecords(result.data);
    return result;
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteClient/${Sr_No}`);

    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Client Details", 20, 10);

    const columns = [
      "Client ID",
      "Org Category",
      " Company Name",
      "Owner Name",
      "Mobile",
      "Pin Code",
      "Address",
    ];

    const rows = displayData.map((row) => [
      row.Client_Id,
      row.Client_Cat,
      row.Owner_Name,
      row.Company_Name,
      row.Mobile_No,
      row.PinCode,
      row.Address,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Client-Table.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Client ID",
      "Org Category",
      " Company Name",
      "Owner Name",
      "Mobile",
      "Pin Code",
      "Address",
    ];
    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        row.Client_Id,
        row.Client_Cat,
        row.Owner_Name,
        row.Company_Name,
        row.Mobile_No,
        row.PinCode,
        row.Address,
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Client-Table");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Client-Table.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.PinCode && row.PinCode.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (row.Client_Id && row.Client_Id.toLowerCase().includes(filterValue)) ||
        (row.Client_Cat &&
          row.Client_Cat.toLowerCase().includes(filterValue)) ||
        (row.Company_Name &&
          row.Company_Name.toLowerCase().includes(filterValue)) ||
        (row.Owner_Name && row.Owner_Name.toLowerCase().includes(filterValue))
        ||
        (row.Mobile_No && row.Mobile_No.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const filterData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Client_Cat === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]);
    }
  };

  return (
    <div>
      <Box height={10} />
      <div className="d-flex justify-content-end mb-2 ml-1">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={uniqueCategory}
          sx={{ width: 180 }}
          onChange={(e, v) => filterData(v)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Filter Org Category" />
          )}
        />
      </div>
      <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
        <TextField onChange={handleFilter} size="small" label="Search..." />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <Tooltip title="Export to PDF">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            {" "}
            <Icon path={mdiFileExcel} size={1} />
          </IconButton>
        </Tooltip>
        <Link to={`/addClient`}>
          <Button variant="contained" endIcon={<AddCircleIcon />}>
            Add
          </Button>
        </Link>
      </Stack>
      <Box height={10} />
      <TableContainer className="" sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Client ID
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Org Category
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Company Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Owner Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Mobile
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Pin Code
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Address
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData && displayData.length > 0 ? (
              displayData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">{row.Client_Id}</TableCell>
                      <TableCell align="left">{row.Client_Cat}</TableCell>
                      <TableCell align="left">{row.Company_Name}</TableCell>
                      <TableCell align="left">{row.Owner_Name}</TableCell>
                      <TableCell align="left">{row.Mobile_No}</TableCell>
                      <TableCell align="left">{row.PinCode}</TableCell>
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.Address}
                      </TableCell>
                      <TableCell className="text-cneter">
                        <IconButton
                          type="button"
                          className="btn shadow-none"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ padding: "0px", border: 0 }}
                        >
                          <i
                            className="ri-more-2-fill"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </IconButton>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              to={`/updateClient/${row.Sr_No}`}
                              className="dropdown-item"
                            >
                              <i
                                className="ri-edit-2-line"
                                style={{
                                  color: "blue",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Update</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => deleteUser(row.Sr_No)}
                              className="dropdown-item cursor-pointer"
                            >
                              <i
                                className="ri-delete-bin-3-line"
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Delete</span>
                            </Link>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="mt-3"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={displayData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
