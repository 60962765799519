import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from '../../componets/apiConfig';

const OrgsCategory = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [Sr_No, setId] = useState("");
  const [Category_Name, setName] = useState("");
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();

  useEffect(() => {
    (async () => {
      const result = await Load();
      setData(result.data);
    })();
  }, []);

  async function Load() {
    const result = await axios.get(
      `${apiUrl}/getOrgCategories`
    );
    setData(result.data);
    setRecords(result.data);
    return result;
  }

  async function save(event) {
    event.preventDefault();

    const error = {};

    if (!Category_Name) {
      error.Category_Name = "Enter Expense Category";
    }

    if (Object.keys(error).length === 0) {
      try {
        await axios.post(`${apiUrl}/addOrgCategory`, {
          Category_Name: Category_Name,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
        });
        Swal.fire(
          "Success!",
          "Your Org Category has been Created.",
          "success"
        );
        setError("");
        setName("");
        setId("");

        Load();
      } catch (err) {
        alert("User Registration Failed");
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    setName(data.Category_Name);
    setId(data.Sr_No);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {
      const updatedData = {
        Sr_No: Sr_No,
        Category_Name: Category_Name,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
      };

      await axios.put(
        `${apiUrl}/updateOrgCategory/` + Sr_No,
        updatedData
      );
      setData((prevData) =>
        prevData.map((data) =>
          data.Sr_No === Sr_No ? { ...data, ...updatedData } : data
        )
      );
      setName("");
      setId("");
      setEditingItem(null);
      Load();
      Swal.fire(
        "Success!",
        "Your Org Category has been updated.",
        "success"
      );
    } catch (err) {
      alert("Registration Failed");
    }
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteOrgCategory/${Sr_No}`);
    Swal.fire("Deleted!", "Your file has been Deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Org Categories", 20, 10);

    const columns = ["Org Categories"];

    const rows = records.map((row) => [row.Category_Name]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("OrgCategory-Table.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Org Categories"];
    const rows = [columns]; 
    records.forEach((row) => {
      rows.push([row.Category_Name]);
    });
  
    const workSheet = XLSX.utils.aoa_to_sheet(rows); 
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Org Table");
  
    // Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  
    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  
    // Download
    XLSX.writeFile(workBook, "OrgCategory-Table.xlsx");
  };
  

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
  
        (row.Category_Name && row.Category_Name.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Add Org Category</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Box height={10} />
            <Stack
              direction="row"
              spacing={2}
              className="mx-2 my-2 mb-4"
              justifyContent="flex-end"
            >
              <div>
                <Tooltip title="Export to PDF">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadPDF();
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Export to Excel">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadExcel();
                    }}
                  >
                    {" "}
                    <Icon path={mdiFileExcel} size={1} />
                  </IconButton>
                </Tooltip>
                <TextField
                  size="small"
                  sx={{ width: 300 }}
                  label="Organization Category"
                  id="Category_Name"
                  required
                  value={Category_Name}
                  onChange={(event) => {
                    setName(event.target.value);
                    setError("");
                  }}
                  error={Boolean(error.Category_Name)}
                  helperText={error.Category_Name}
                />
              </div>
              <div>
                {editingItem ? (
                  <Button
                    variant="contained"
                    endIcon={<AddCircleIcon />}
                    onClick={update}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<AddCircleIcon />}
                    onClick={save}
                  >
                    Add
                  </Button>
                )}
              </div>
            </Stack>

            <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
       
              <TextField onChange={handleFilter} size="small" label="Search..." />
            </Stack>
            <Box height={14} />

            <TableContainer className="" sx={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                   
                    <TableCell align="left" style={{ minWidth: "400px" }}>
                    Organization Categories
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                         
                          <TableCell align="left">
                            {row.Category_Name}
                          </TableCell>

                          <TableCell className="text-cneter">
                            <IconButton
                              type="button"
                              className="btn shadow-none"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px", border: 0 }}
                            >
                               <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
                            </IconButton>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => editExpenses(row)}
                                >
                                  <i
                                    className="ri-edit-2-line"
                                    style={{
                                      color: "blue",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Update</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    deleteUser(row.Sr_No);
                                  }}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i
                                    className="ri-delete-bin-3-line"
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Delete</span>
                                </Link>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="mt-3"
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={records.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgsCategory;
