import React from "react";

const Payroll = () => {
  return (
    <div>
      <hr />
  
    </div>
  );
};

export default Payroll;
