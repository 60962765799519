import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../componets/apiConfig";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import useFullPageLoader from "../../mui/useFullPageLoader";

const Home = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [clientCounts, setClientCounts] = useState({
    TotalClientRecords: 0,
    ClientRecordsAddedThisMonth: 0,
  });

  useEffect(() => {
    showLoader();
    axios
      .get(`${apiUrl}/getClientCounts`)
      .then((res) => {
        const result = res.data;
        setClientCounts(result);
        hideLoader();
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [status, setDailyVisitStatus] = useState({
    InprocessCount: 0,
    PendingCount: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const statusCounts = await axios.get(`${apiUrl}/getStatusCounts`);
        setDailyVisitStatus(statusCounts.data);
        hideLoader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [OrderStatus, setOrderStatus] = useState({
    InprocessCount: 0,
    PendingCount: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const statusCounts = await axios.get(`${apiUrl}/getOrderStatusCounts`);
        setOrderStatus(statusCounts.data);
        hideLoader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [stats, setTotalExpenses] = useState({
    TotalExpensesCurrentMonth: 0,
    TotalExpensesLastMonth: 0,
    Progress: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const stats = await axios.get(`${apiUrl}/getExpenseStats`);
        setTotalExpenses(stats.data);
        hideLoader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [netTotal, setNetTotal] = useState({
    TotalNetTotalCurrentMonth: 0,
    TotalNetTotalLastMonth: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const stats = await axios.get(`${apiUrl}/getOrderStats`);
        setNetTotal(stats.data);
        hideLoader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const CountWithCommas = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString();
  };

  const OrderChange =
    netTotal.TotalNetTotalCurrentMonth - netTotal.TotalNetTotalLastMonth;
  let percentageOrderChange = 0;
  if (netTotal.TotalNetTotalLastMonth !== null) {
    percentageOrderChange = (
      (OrderChange / Math.abs(netTotal.TotalNetTotalLastMonth)) *
      100
    ).toFixed(2);
  }
  const isOrderIncrease = OrderChange > 0;

  const expenseChange =
    stats.TotalExpensesCurrentMonth - stats.TotalExpensesLastMonth;
  let percentageChange = 0;
  if (stats.TotalExpensesLastMonth !== null) {
    percentageChange = (
      (expenseChange / Math.abs(stats.TotalExpensesLastMonth)) *
      100
    ).toFixed(2);
  }
  const isExpenseIncrease = expenseChange > 0;

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Dashboard</h4>
        </div>

        <div className="row row-cols-1 row-cols-xxl-6 row-cols-lg-3 row-cols-md-2">
          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <Link to="/viewClient">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5
                        className="text-muted text-uppercase fs-13 mt-0"
                        title="Number of Customers"
                      >
                        Clients
                      </h5>

                      <h3
                        className="my-3"
                        style={{
                          color: "#75757d",
                        }}
                      >
                        {CountWithCommas(clientCounts.TotalClientRecords)}
                      </h3>
                      <p className="mb-0 text-muted text-truncate">
                        <span className="badge bg-success me-1">
                          <i className="ri-arrow-up-line"></i>{" "}
                          {CountWithCommas(
                            clientCounts.ClientRecordsAddedThisMonth
                          )}
                        </span>
                        <span>In this month</span>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title text-bg-success rounded rounded-3 fs-3 widget-icon-box-avatar shadow">
                        <i className="ri-user-settings-fill"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <Link to="/viewOrder">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5
                        className="text-muted text-uppercase fs-13 mt-0"
                        title="Average Revenue"
                      >
                        Orders
                      </h5>
                      <h3
                        className="my-3"
                        style={{
                          color: "#75757d",
                        }}
                      >
                        ₹{CountWithCommas(netTotal.TotalNetTotalCurrentMonth)}
                      </h3>
                      <p className="mb-0 text-muted text-truncate">
                        <span
                          className={`badge ${
                            isOrderIncrease ? "bg-success" : "bg-danger"
                          } me-1`}
                        >
                          <i
                            className={`ri-arrow-${
                              isOrderIncrease ? "up" : "down"
                            }-line`}
                          ></i>{" "}
                          {Math.abs(percentageOrderChange)}%
                        </span>
                        <span>In this month</span>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title text-bg-info rounded rounded-3 fs-3 widget-icon-box-avatar shadow">
                        <i className="ri-shopping-basket-2-line"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <Link to="/viewBill">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5
                        className="text-muted text-uppercase fs-13 mt-0"
                        title="Average Revenue"
                      >
                        Expenses
                      </h5>
                      <h3
                        className="my-3"
                        style={{
                          color: "#75757d",
                        }}
                      >
                        ₹{CountWithCommas(stats.TotalExpensesCurrentMonth)}
                      </h3>
                      <p className="mb-0 text-muted text-truncate">
                        <span
                          className={`badge ${
                            isExpenseIncrease ? "bg-danger" : "bg-success"
                          } me-1`}
                        >
                          <i
                            className={`ri-arrow-${
                              isExpenseIncrease ? "up" : "down"
                            }-line`}
                          ></i>{" "}
                          {Math.abs(percentageChange)}%
                        </span>
                        <span>In this month</span>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title text-bg-danger rounded rounded-3 fs-3 widget-icon-box-avatar shadow">
                        <CurrencyRupeeIcon />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <Link to="/viewVisit">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5
                        className="text-muted text-uppercase fs-13 mt-0"
                        title="Growth"
                      >
                        Daily Visits
                      </h5>
                      <div className="row">
                        <div className="col-md-6">
                          <h3
                            className="my-2 text-center"
                            style={{
                              color: "#75757d",
                            }}
                          >
                            {CountWithCommas(status.PendingCount)}
                          </h3>
                        </div>
                        <div className="col-md-6">
                          <h3
                            className="my-2 text-center"
                            style={{
                              color: "#75757d",
                            }}
                          >
                            {CountWithCommas(status.InprocessCount)}
                          </h3>
                        </div>
                      </div>
                      <p className="mb-0 text-muted text-truncate">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h5 className="text-center">Pending</h5>
                          </div>
                          <div className="col-md-6">
                            {" "}
                            <h5 className="text-center">Inprocess</h5>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title text-bg-primary rounded rounded-3 fs-3 widget-icon-box-avatar shadow">
                        <i className="ri-map-pin-user-fill"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <Link to="/viewOrder">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5
                        className="text-muted text-uppercase fs-13 mt-0"
                        title="Growth"
                      >
                        Order Status
                      </h5>
                      <div className="row">
                        <div className="col-md-6">
                          <h3
                            className="my-2 text-center"
                            style={{
                              color: "#75757d",
                            }}
                          >
                            {CountWithCommas(OrderStatus.PendingCount)}
                          </h3>
                        </div>
                        <div className="col-md-6">
                          <h3
                            className="my-2 text-center"
                            style={{
                              color: "#75757d",
                            }}
                          >
                            {CountWithCommas(OrderStatus.InprocessCount)}
                          </h3>
                        </div>
                      </div>
                      <p className="mb-0 text-muted text-truncate">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h5 className="text-center">Pending</h5>
                          </div>
                          <div className="col-md-6">
                            {" "}
                            <h5 className="text-center">Inprocess</h5>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title text-bg-warning rounded rounded-3 fs-3 widget-icon-box-avatar shadow">
                        <i class="ri-truck-fill"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5
                      className="text-muted text-uppercase fs-13 mt-0"
                      title="Conversation Ration"
                    >
                      Conversation
                    </h5>
                    <h3 className="my-3">9.62%</h3>
                    <p className="mb-0 text-muted text-truncate">
                      <span className="badge bg-success me-1">
                        <i className="ri-arrow-up-line"></i> 3.07%
                      </span>
                      <span>Since last month</span>
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title text-bg-warning rounded rounded-3 fs-3 widget-icon-box-avatar">
                      <i className="ri-pulse-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card widget-icon-box">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5
                      className="text-muted text-uppercase fs-13 mt-0"
                      title="Conversation Ration"
                    >
                      Balance
                    </h5>
                    <h3 className="my-3">$168.5k</h3>
                    <p className="mb-0 text-muted text-truncate">
                      <span className="badge bg-success me-1">
                        <i className="ri-arrow-up-line"></i> 18.34%
                      </span>
                      <span>Since last month</span>
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title text-bg-dark rounded rounded-3 fs-3 widget-icon-box-avatar">
                      <i className="ri-wallet-3-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {loader}
    </div>
  );
};

export default Home;
