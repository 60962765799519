import React, { useState, useEffect , useRef} from "react";
import AddproformaProduct from "./AddproformaProduct";
import axios from "axios";
import { useUser } from "../../componets/UserContext";
import SuccessNavigation from "../Alerts/SuccessNavigation";
import { apiUrl } from "../../componets/apiConfig";

let prodcts = [];
const Proforma = () => {
  const { userData } = useUser();
  const [pName, setpName] = useState("");
  const [pPreice, setpPreice] = useState("");
  const [pQty, setpQty] = useState("");
  const [pTotal, setpTotal] = useState("0");
  const [grossTotal, setgrossTotal] = useState("0");
  const [discount, setDiscount] = useState("0");
  const [netTotal, setnetTotal] = useState("0");
  const [formError, setFormError] = useState({});
  const [pError, setproductError] = useState({});
  const [id, setId] = useState(null);
  const [invoices, setInvoices] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [IspreviousInv, setIspreviousInv] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const buttonElement = useRef(null);

  const handleDataInsertion = () => {
    setShowSuccessAlert(true);
  };

  //fetching last invoice number
  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getProformaInvNo`);
      setInvoices(result.data);
      setIspreviousInv(true);
    })();
  }, []);

  useEffect(() => {
    // Generate the next invoice number based on existing data
    if (IspreviousInv) {
      const nextInvoiceNumber = generateNextInvoiceNumber(invoices);
      setInvoiceNumber(nextInvoiceNumber);
    }
  }, [IspreviousInv]);

  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices.length === 0) {
      return "INV-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `INV-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }

  const pnameChange = (event) => {
    setpName(event.target.value);
  };
  const pPreiceChange = (e) => {
    const input = e.target.value;
    const numericInput = input.match(/^\d*\.?\d*/)[0];

    setpPreice(numericInput);
    updateTotal(numericInput, pQty);
  };
  const pQtyChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setpQty(numericInput);
    updateTotal(pPreice, numericInput);
  };
  const updateTotal = (price, quantity) => {
    let total = price * quantity;
    setpTotal(total);
  };
  const [proforma, setproforma] = useState({
    Invoice_No: "",
    Owner_Name: "",
    Org_Name: "",
    GST_NO: "",
    Mobile_No: "",
    Email: "",
    Invoice_Date: "",
    Due_Date: "",
    Address: "",
    Added_By_Id: null,
    Gross_Total: "",
    Dsicount: "",
    Net_Total: "",
    Added_By: "",
    Added_On: "",
  });

  const addProductClick = () => {
    const prodctsObj = {
      id: Math.random().toString(),
      name: pName,
      price: pPreice,
      quantity: pQty,
      total: pTotal,
    };
    const producterror = {};
    if (!prodctsObj.name) {
      producterror.product = "Enter Product  Name";
    }
    if (!prodctsObj.price) {
      producterror.price = "Enter Price";
    }
    if (!prodctsObj.quantity) {
      producterror.qty = "Enter Quantity ";
    }

    setproductError(producterror);

    if (Object.keys(producterror).length === 0) {
      prodcts.push(prodctsObj);
      const totalAmount = prodcts.reduce(
        (acc, prodcts) => acc + prodcts.total,
        0
      );
      setgrossTotal(totalAmount);
      setnetTotal(totalAmount);
      setpName("");
      setpPreice("");
      setpQty("");
      setpTotal(0);
    }
  };

  const grandTotal = (event) => {
    const total = event.target.value;
    setgrossTotal(total);
    TotalNetAmount(total, discount);
  };
  const discountAmount = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setDiscount(numericInput);
    TotalNetAmount(grossTotal, numericInput);
  };
  const netGrandTotal = (event) => {
    const total = event.target.value;
    setnetTotal(total);
  };
  const TotalNetAmount = (gross, discount) => {
    const net = gross - discount;
    setnetTotal(net);
  };

  const ProformaSubmit = (event) => {
    event.preventDefault();

    debugger;
    const updatedproforma = {
      ...proforma,
      Invoice_No: invoiceNumber,
      Gross_Total: grossTotal,
      Dsicount: discount,
      Net_Total: netTotal,
      products: prodcts,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const errors = {};
    const emaiRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const mobileRegx = /^([6-9][0-9]{9,11})$/;
    if (!updatedproforma.Org_Name) {
      errors.org_Name = "Enter Organization  Name";
    }
    if (!updatedproforma.Owner_Name) {
      errors.Owner_Name = "Enter Owner Name";
    }
    if (!updatedproforma.Mobile_No) {
      errors.mobile_No = "Enter Mobile Number";
    } else if (!mobileRegx.test(updatedproforma.Mobile_No)) {
      errors.mobile_No = "Invalid Mobile Number!";
    }
    if (!updatedproforma.Email) {
      errors.Email = "Enter Email";
    } else if (!emaiRegx.test(updatedproforma.Email)) {
      errors.Email = "Invalid Email Address!";
    }
    if (!updatedproforma.Invoice_Date) {
      errors.Invoice_Date = "Select Invoice Date";
    }
    if (!updatedproforma.Due_Date) {
      errors.Due_Date = "Select Due Date";
    }
    if (!updatedproforma.Gross_Total) {
      errors.Gross_Total = "Gross Total is required!";
    }
    if (!updatedproforma.Dsicount) {
      errors.Dsicount = "Dsicount is required!";
    }
    if (!updatedproforma.Net_Total) {
      errors.Net_Total = "Net Total is required!";
    }
    if (Object.keys(errors).length === 0) {
      buttonElement.current.disabled = true;
      axios
        .post(`${apiUrl}/addProforma`, updatedproforma)
        .then((res) => {
          const Id = res.data.insertedIdentityValue;
          setId(Id);
          handleDataInsertion();
          buttonElement.current.disabled = false;
          prodcts = [];
        })
        .catch((err) => console.error(err));
    } else {
      setFormError(errors);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
            <h4 className="page-title">Proforma Invoice</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={ProformaSubmit}>
                <div className="row">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Invoice Number*</label>
                    <input
                      type="text"
                      id="txtOrgName"
                      name="txtOrgName"
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      disabled
                      className="form-control"
                      placeholder="Invoice Number"
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Organization Name*</label>
                    <input
                      type="text"
                      id="txtOrgName"
                      name="txtOrgName"
                      onChange={(e) =>
                        setproforma({ ...proforma, Org_Name: e.target.value })
                      }
                      className="form-control"
                      placeholder="Organization Name"
                    />
                    <p style={{ color: "red" }}>{formError.org_Name}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Owner Name*</label>
                    <input
                      type="text"
                      id="txtOwnerName"
                      name="txtOwnerName"
                      onChange={(e) =>
                        setproforma({ ...proforma, Owner_Name: e.target.value })
                      }
                      className="form-control"
                      placeholder="Owner Name"
                    />
                    <p style={{ color: "red" }}>{formError.Owner_Name}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">GST Number</label>
                    <input
                      type="text"
                      id="txtGSTNum"
                      maxLength="15"
                      name="txtGSTNum"
                      value={proforma.GST_NO}
                      onChange={(e) => {
                        const input = e.target.value.toUpperCase();
                        setproforma({ ...proforma, GST_NO: input });
                      }}
                      className="form-control"
                      placeholder="GST Number"
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Mobile Number*</label>
                    <input
                      type="text"
                      id="txtMobile"
                      name="txtMobile"
                      maxLength={10}
                      value={proforma.Mobile_No}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setproforma({ ...proforma, Mobile_No: numericInput });
                      }}
                      className="form-control"
                      placeholder="Mobile Number"
                    />
                    <p style={{ color: "red" }}>{formError.mobile_No}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Email*</label>
                    <input
                      type="text"
                      id="txtEmail"
                      name="txtEmail"
                      onChange={(e) =>
                        setproforma({ ...proforma, Email: e.target.value })
                      }
                      className="form-control"
                      placeholder="Email"
                    />
                    <p style={{ color: "red" }}>{formError.Email}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Invoice Date*</label>
                    <input
                      type="Date"
                      id="txtInvoiceDate"
                      name="txtInvoiceDate"
                      onChange={(e) =>
                        setproforma({
                          ...proforma,
                          Invoice_Date: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Invoice Date"
                    />
                    <p style={{ color: "red" }}>{formError.Invoice_Date}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Due Date*</label>
                    <input
                      type="Date"
                      id="txtDueDate"
                      name="txtDueDate"
                      onChange={(e) =>
                        setproforma({ ...proforma, Due_Date: e.target.value })
                      }
                      className="form-control"
                      placeholder="Due Date"
                    />
                    <p style={{ color: "red" }}>{formError.Due_Date}</p>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <label className="form-label">Address</label>
                    <textarea
                      type="text"
                      id="txtAddress"
                      name="txtAddress"
                      onChange={(e) =>
                        setproforma({ ...proforma, Address: e.target.value })
                      }
                      className="form-control"
                      placeholder="Address"
                    />
                  </div>
                </div>
                <hr className="row mt-1" />
                <div className="row mt-2">
                  <h4>Product Detail</h4>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Product Name*</label>
                    <input
                      type="text"
                      id="txtProductName"
                      name="txtProductName"
                      onChange={pnameChange}
                      value={pName}
                      className="form-control"
                      placeholder="Product Name"
                    />
                    <p style={{ color: "red" }}>{pError.product}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Price*</label>
                    <input
                      type="text"
                      id="txtPrice"
                      name="txtPrice"
                      onChange={pPreiceChange}
                      value={pPreice}
                      className="form-control"
                      placeholder="Price"
                    />
                    <p style={{ color: "red" }}>{pError.price}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Quantity*</label>
                    <input
                      type="text"
                      id="txtQuantity"
                      name="txtQuantity"
                      onChange={pQtyChange}
                      value={pQty}
                      className="form-control"
                      placeholder="Quantity"
                    />
                    <p style={{ color: "red" }}>{pError.qty}</p>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <label className="form-label">Total</label>
                    <input
                      type="text"
                      id="txtTotal"
                      name="txtTotal"
                      className="form-control"
                      value={pTotal}
                      placeholder="Total"
                      defaultValue={0}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-2 text-center">
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="btnAddProduct"
                      name="Add Product"
                      onClick={addProductClick}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {prodcts.length > 0 && (
                      <table
                        id="basic-datatable"
                        className="table table-striped dt-responsive nowrap w-100 basic-datatable"
                        style={{ display: "table" }}
                      >
                        <thead style={{ width: "100% !important" }}>
                          <tr>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <AddproformaProduct productList={prodcts} />
                      </table>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <label className="form-label">Gross Total*</label>
                    <input
                      type="text"
                      id="txtGrossTotal"
                      disabled
                      value={grossTotal}
                      onChange={grandTotal}
                      className="form-control"
                      placeholder="Gross Total"
                      defaultValue={0}
                    />
                    <p style={{ color: "red" }}>{formError.Gross_Total}</p>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <label className="form-label">Discount*</label>
                    <input
                      type="text"
                      id="txtDiscount"
                      value={discount}
                      onChange={discountAmount}
                      className="form-control"
                      placeholder="Discount"
                      defaultValue={0}
                    />
                    <p style={{ color: "red" }}>{formError.Discount}</p>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <label className="form-label">Net Total*</label>
                    <input
                      type="text"
                      id="txtNetTotal"
                      value={netTotal}
                      onChange={netGrandTotal}
                      className="form-control"
                      placeholder="Net Total"
                      defaultValue={0}
                      disabled
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-2 text-center">
                    {prodcts.length > 0 && (
                      <button
                        className="btn btn-primary"
                        ref={buttonElement}
                        type="submit"
                        id="btnSubmit"
                        style={{ display: "block" }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessNavigation
          text="Data Inserted Successfully!"
          url={`/reporting/${id}`}
        />
      )}
    </div>
  );
};
export default Proforma;
