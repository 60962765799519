import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";

export default function VendorDataTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    Load();
  }, []);

  async function Load() {
    const vendorResult = await axios.get(`${apiUrl}/vendors`);
    const supplierResult = await axios.get(`${apiUrl}/suppliers`);

    // Create a dictionary (map) to store supplier data for each Sr_No
    const supplierDataMap = new Map();

    // Populate the supplier data map
    supplierResult.data.forEach((supplier) => {
      const srNo = supplier.Sup_Id;
      if (!supplierDataMap.has(srNo)) {
        supplierDataMap.set(srNo, []);
      }
      supplierDataMap.get(srNo).push({
        Supplier_Name: supplier.Supplier_Name,
        Supplier_Mobile: supplier.Supplier_Mobile,
      });
    });

    // Merge vendor data with supplier data
    const mergedData = vendorResult.data.map((vendor) => {
      const supplierInfo = supplierDataMap.get(vendor.Sr_No) || [];
      return {
        ...vendor,
        suppliers: supplierInfo,
      };
    });

    setData(mergedData);
    setRecords(mergedData);
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteVendor/${Sr_No}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Vendor Details", 20, 10);

    const columns = [
      "Org Name",
      "Org Mobile",
      "Supplier Name",
      "Supplier Mobile",
      " Address"
    ];

    const rows = records.map((row) => [
      row.Org_Name,
      row.Org_Mobile,
      row.suppliers.map((supplier) => supplier.Supplier_Name).join("\n"),
      row.suppliers.map((supplier) => supplier.Supplier_Mobile).join("\n"),
      row.Address
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Vendor-Records.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Org Name",
      "Org Mobile",
      "Supplier Name",
      "Supplier Mobile",
      "Address"
    ];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Org_Name,
        row.Org_Mobile,
        row.suppliers.map((supplier) => supplier.Supplier_Name).join(", "),
        row.suppliers.map((supplier) => supplier.Supplier_Mobile).join(", "),
        row.Address
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vendor-Table");

    //buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //download
    XLSX.writeFile(workBook, "Vendor-Table.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Org_Name && row.Org_Name.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (row.Org_Mobile && row.Org_Mobile.toLowerCase().includes(filterValue)) ||
        row.suppliers.some(
          (supplier) =>
            supplier.Supplier_Name.toLowerCase().includes(filterValue) ||
            supplier.Supplier_Mobile.toLowerCase().includes(filterValue)
        )
        
      );
    });
    setRecords(filteredData);
  }

  return (
    <div>
      <Box height={10} />
      <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
        <TextField onChange={handleFilter} size="small" label="Search..." />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <Tooltip title="Export to PDF">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            {" "}
            <Icon path={mdiFileExcel} size={1} />
          </IconButton>
        </Tooltip>
        <Link to={`/addVender`}>
          <Button variant="contained" endIcon={<AddCircleIcon />}>
            Add
          </Button>
        </Link>
      </Stack>
      <Box height={10} />
      <TableContainer className="" sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Org Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Org Mobile
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Supplier Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Supplier Mobile
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Address
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records && records.length > 0 ? (
              records
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">{row.Org_Name}</TableCell>
                      <TableCell align="left">{row.Org_Mobile}</TableCell>
                      <TableCell align="left">
                        {row.suppliers.map((supplier, i) => (
                          <div key={i}>{supplier.Supplier_Name}</div>
                        ))}
                      </TableCell>
                      <TableCell align="left">
                        {row.suppliers.map((supplier, i) => (
                          <div key={i}>{supplier.Supplier_Mobile}</div>
                        ))}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.Address}
                      </TableCell>
                      <TableCell className="text-cneter">
                        <IconButton
                          type="button"
                          className="btn shadow-none"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ padding: "0px", border: 0 }}
                        >
                           <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
                        </IconButton>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              to={`/updateSupplier/${row.Sr_No}`}
                              className="dropdown-item"
                            >
                              <i
                                className="ri-edit-2-line"
                                style={{
                                  color: "blue",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Update</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => deleteUser(row.Sr_No)}
                              className="dropdown-item cursor-pointer"
                            >
                              <i
                                className="ri-delete-bin-3-line"
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Delete</span>
                            </Link>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="mt-3"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
