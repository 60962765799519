import * as React from "react";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";

const ViewTarget = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const columns = [
    {
      name: "Sales Person",
      selector: (row) => row.Sales_Person,
      sortable: true,
    },
    {
      name: "Month",
      selector: (row) => row.Month_Name,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.Year,
      sortable: true,
    },
    {
      name: "Overall Monthly Target",
      selector: (row) => row.Overall_Monthly_Target,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Id)}
            aria-controls="row-menu"
            aria-haspopup="true"
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Link
                to={`/updateTarget/${selectedRowSrNo}`}
                className="dropdown-item"
              >
                <span> Update</span>
              </Link>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleMenuClose();
                deleteUser(selectedRowSrNo);
              }}
              style={{ color: "red" }}
            >
              <span> Delete</span>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    loadExpensesData();
  }, []);

  const loadExpensesData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getTargetSalesPerson`);
      const status = Array.from(
        new Set(response.data.map((row) => row.Month_Name))
      );
      setUniqueStatus(status);
      setData(response.data);
      setRecords(response.data);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };

  const handleMenuClick = (event, Id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(Id);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    loadExpensesData();
  };

  async function handleDelete(Id) {
    await axios.delete(`${apiUrl}/deleteTargetSalesPerson/${Id}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    loadExpensesData();
  }

  

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Sales_Person &&
          row.Sales_Person.toLowerCase().includes(filterValue)) ||
        (row.Month_Name &&
          row.Month_Name.toLowerCase().includes(filterValue)) ||
        (row.Year && row.Year.toLowerCase().includes(filterValue)) ||
        (row.Overall_Monthly_Target &&
          row.Overall_Monthly_Target.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("SalesPerson Targets", 20, 10);

    const columns = ["Sales Person", "Month", "Year", "Overall Monthly Target"];

    const rows = displayData.map((row) => [
      row.Sales_Person,
      row.Month_Name,
      row.Year,
      row.Overall_Monthly_Target,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("SalesPerson_Target.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sales Person", "Month", "Year", "Overall Monthly Target"];

    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        row.Sales_Person,
        row.Month_Name,
        row.Year,
        row.Overall_Monthly_Target,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sales Person Targets");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "SalesPerson_Target.xlsx");
  };

  const filterData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Month_Name === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]); 
    }
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">View Target</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <div>
              <>
                <Box height={10} />
                <div className="d-flex justify-content-end mb-2 ml-1">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={uniqueStatus}
                    sx={{ width: 180 }}
                    onChange={(e, v) => filterData(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filter By Month"
                      />
                    )}
                  />
                </div>
                <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                  <Link to={"/setTarget"}>
                    <Button variant="contained" endIcon={<AddCircleIcon />}>
                      Add
                    </Button>
                  </Link>
                </Stack>
                <Box height={10} />

                <DataTable
                  columns={columns}
                  data={displayData}
                  fixedHeader
                  pagination
                />
              </>
              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTarget;
