import React from 'react'
import ProformaTableTd from './ProformaTableTd'
const AddproformaProduct = (props) => {
  return (
    <tbody>{
      props.productList.map(
        productDetail => (
              <ProformaTableTd
                  key={productDetail.id}
                  name={productDetail.name}
                  price={productDetail.price}
                  quantity={productDetail.quantity}
                  total={productDetail.total}>
              </ProformaTableTd>
          )
      )
  }</tbody>
  )
}
export default AddproformaProduct;
