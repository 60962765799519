import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../componets/UserContext";
import useButtonLoader from "../../mui/useButtonLoader";
import MySelect from "../../mui/MySelect.jsx";

const monthOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const yearOptions = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

const UpdateTarget = () => {
  const [buttonElement, setLoading] = useButtonLoader(
    "Update Target",
    "Updating..."
  );
  const navigate = useNavigate();
  const { userData } = useUser();
  const { Id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [target, setTarget] = useState({
    Sales_Person_Id: "",
    Sales_Person: "",
    Month_Value: "",
    Month_Name: "",
    Year: "",
    Overall_Monthly_Target: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const updatedTargetData = {
        ...target,
        Sales_Person_Id: salesPersonId || target.Sales_Person_Id,
        Sales_Person: salesPersonName || target.Sales_Person,
        Month_Value: monthValue || target.Month_Value,
        Month_Name: monthName || target.Month_Name,
        Year: yearName || target.Year,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
      };

      const response = await axios.put(
        `${apiUrl}/updateTargetSalesPerson/${Id}`,
        updatedTargetData
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: "Target updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        navigate("/viewTarget");
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to update target.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating target:", error);

      Swal.fire({
        title: "Error",
        text: "Failed to update target. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadStaffData = async () => {
      try {
        showLoader();
        const response = await axios.get(`${apiUrl}/getSalesPersons`);
        const StaffDate = response.data;
        const salesPerson = StaffDate.map((staff) => ({
          label: `${staff.First_Name} ${staff.Last_Name}`,
          value: staff.Id,
        }));
        setsalesPersonOption(salesPerson);
      } catch (error) {
        console.error("Error loading expenses data:", error);
      } finally {
        hideLoader();
      }
    };
    loadStaffData();
  }, []);

  useEffect(() => {
    loadTargetData();
  }, [Id]);

  const loadTargetData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getTargetSalesPerson/${Id}`);
      const targetData = response.data;
      setTarget(targetData);
      setselectedSalesPerson({
        label: targetData.Sales_Person,
        value: targetData.Sales_Person_Id,
      });
      setselectedMonth({
        label: targetData.Month_Name,
        value: targetData.Month_Value,
      });
      setselectedYear({ label: targetData.Year, value: targetData.Year });
    } catch (error) {
      console.error("Error loading target data:", error);
    } finally {
      hideLoader();
    }
  };
  const [selectedSalesPerson, setselectedSalesPerson] = useState([]);
  const [salesPersonOption, setsalesPersonOption] = useState([]);
  const [salesPersonName, setsalesPersonName] = useState("");
  const [salesPersonId, setsalesPersonId] = useState(null);

  const [selectedMonth, setselectedMonth] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [monthValue, setMonthValue] = useState(null);

  const [selectedYear, setselectedYear] = useState([]);
  const [yearName, setYearName] = useState("");
  

  const handleSalesPersonChange = (selectedOption) => {
    if (selectedOption) {
      setsalesPersonName(selectedOption.label);
      setsalesPersonId(selectedOption.value);
    } else {
      setsalesPersonName(target.Sales_Person);
      setsalesPersonId(target.Sales_Person_Id);
    }
    setselectedSalesPerson(selectedOption);
  };

  const handleMonthChange = (selectedOption) => {
    if (selectedOption) {
      setMonthName(selectedOption.label);
      setMonthValue(selectedOption.value);
    } else {
      setMonthName(target.Month_Name);
      setMonthValue(target.Month_Value);
    }
    setselectedMonth(selectedOption);
  };

  const handleYearChange = (selectedOption) => {
    if (selectedOption) {
      setYearName(selectedOption.label);
    } else {
      setYearName(target.Year);
    }
    setselectedYear(selectedOption);
  };



  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Update Target</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Sales_Person">
                      Sales Person*
                    </label>
                    <Select
                      name="Sales_Person"
                      value={selectedSalesPerson}
                      options={salesPersonOption}
                      onChange={handleSalesPersonChange}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Month_Name">
                      Month*
                    </label>
                    <Select
                      name="Month_Name"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      options={monthOptions}
                      id="Month_Name"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Year">
                      Year*
                    </label>
                    <Select
                      name="Year"
                      options={yearOptions}
                      value={selectedYear}
                      onChange={handleYearChange}
                      id="Year"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="Overall_Monthly_Target"
                    >
                      Overall Monthly Target*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Overall_Monthly_Target"
                      value={target.Overall_Monthly_Target}
                      onChange={(e) =>
                        setTarget({
                          ...target,
                          Overall_Monthly_Target: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    ref={buttonElement}
                  >
                    Update Target
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default UpdateTarget;
