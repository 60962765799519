import React, { useState, useEffect, useRef } from "react";
import AddOrderProduct from "./AddOrderProduct";
import axios from "axios";
import Select from "react-select";
import { useUser } from "../../componets/UserContext";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { useNavigate } from "react-router-dom";

const Status = [
  { label: "Paid", value: "Paid" },
  { label: "Partial Paid", value: "Partial Paid" },
  { label: "Unpaid", value: "Unpaid" },
];

const Payment_Mode = [
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "Online", value: "Online" },
];

const Bank = [
  { label: "SBI", value: "SBI" },
  { label: "HDFC", value: "HDFC" },
  { label: "KOTAK", value: "KOTAK" },
  { label: "INDUS BANK", value: "INDUS BANK" },
  { label: "MAHARASHTRA BANK", value: "MAHARASHTRA BANK" },
  { label: "DNS BANK", value: "DNS BANK" },
  { label: "ICICI BANK", value: "ICICI BANK" },
];

const GST = [
  { label: "0%", value: "0" },
  { label: "5%", value: "5" },
  { label: "12%", value: "12" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
];

let prodcts = [];
const CreateOrder = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [pId, setpId] = useState(null);
  const [pName, setpName] = useState("");
  const [pNameOption, setpNameOption] = useState([]);
  const [selectedpName, setSelectedpName] = useState([]);
  const [pPreice, setpPreice] = useState(0);
  const [pQty, setpQty] = useState("");
  const [pTotal, setpTotal] = useState(0);
  const [grossTotal, setgrossTotal] = useState("0");
  const [discount, setDiscount] = useState("0");
  const [formError, setFormError] = useState({});
  const [pError, setproductError] = useState({});
  const [invoices, setInvoices] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [IspreviousInv, setIspreviousInv] = useState(false);
  const buttonElement = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [selectedPayModeValue, setSelectedPayModeValue] = useState("");
  const [nettotalAmount, setNetTotalAmount] = useState(null);
  const [paidValue, setPaidValue] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankValue, setSelectedBankValue] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [ClientNameOptions, setClientNameOptions] = useState([]);
  const [ClientNameName, setClientNameName] = useState("");
  const [ClientNameId, setClientNameId] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState([]);
  const [selectedGST, setSelectedGST] = useState([]);
  const [selectedGSTValue, setSelectedGSTValue] = useState("");
  const [selectedCGST, setSelectedCGST] = useState(null);
  const [selectedSGST, setSelectedSGST] = useState(null);
  const [gstAmountValue, setGstAmountValue] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getProducts`);
      const res = result.data;
      const options = res.map((res) => ({
        label: res.Product_Name,
        value: res.Id,
      }));
      setpNameOption(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getClient`);
      const res = result.data;
      const options = res.map((res) => ({
        label: res.Company_Name,
        value: res.Sr_No,
      }));
      setClientNameOptions(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await axios.get(`${apiUrl}/getLatestOrderNo`);
      setInvoices(result.data);
      setIspreviousInv(true);
    })();
  }, []);

  useEffect(() => {
    if (IspreviousInv) {
      const nextInvoiceNumber = generateNextInvoiceNumber(invoices);
      setInvoiceNumber(nextInvoiceNumber);
    }
  }, [IspreviousInv]);

  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices.length === 0) {
      return "DIGI-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;
    const nextInvoiceNumber = `DIGI-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }
  const pnameChange = (selectedOption) => {
    if (selectedOption) {
      setpName(selectedOption.label);
      setpId(selectedOption.value);
    } else {
      setpId(null);
    }
    setSelectedpName(selectedOption);
    setSelectedGST([null]);
    setDiscount(0);
    setGstAmountValue(0);
    setNetTotalAmount(0);
    setSelectedStatus([null]);
    setPaidValue(0);
    setBalanceValue(0);
    setSelectedBank([null]);
    setSelectedPayMode([null]);
    setOrder({
      ...Order,
      Cheque_No: "",
    });
    setOrder({ ...Order, Reference_No: "" });
  };

  const pPreiceChange = (e) => {
    const input = e.target.value;
    const numericInput = input.match(/^\d*\.?\d*/)[0];

    setpPreice(numericInput);
    updateTotal(numericInput, pQty);
  };

  const pQtyChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setpQty(numericInput);
    updateTotal(pPreice, numericInput);
  };

  const updateTotal = (price, quantity) => {
    let total = price * quantity;
    setpTotal(total);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        //showLoader();
        const response = await axios.get(`${apiUrl}/getProduct/${pId}`);
        const data = response.data;
        setpPreice(data.Product_Price);
      } catch (error) {
        console.error("Error loading expenses data:", error);
      } finally {
        // hideLoader();
      }
    };
    loadData();
  }, [pId]);

  useEffect(() => {
    const loadData = async () => {
      try {
        //showLoader();
        const response = await axios.get(`${apiUrl}/getClient/${ClientNameId}`);
        const data = response.data;
        setMobileNo(data.Mobile_No);
        setBillingAddress(data.Address);
      } catch (error) {
        console.error("Error loading expenses data:", error);
      } finally {
        // hideLoader();
      }
    };
    loadData();
  }, [ClientNameId]);

  const [Order, setOrder] = useState({
    Order_No: "",
    Client_Id: "",
    Client: "",
    Mobile_No: "",
    Order_Date: "",
    Billing_Address: "",
    Total_Amount: "",
    GST_Per: "",
    CGST: "",
    SGST: "",
    Products: [],
    GST_Amount: "",
    Discount: "",
    Gross_Total: "",
    Net_Total: "",
    Payment_Status: "",
    Paid: "",
    Balance: "",
    Order_Status: "",
    Amount: "",
    Balance: "",
    Payment_Mode: "",
    Bank: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Payment_Date: "",
    Remarks: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const addProductClick = () => {
    if (pId !== null && pName !== "" && pQty !== "") {
      const existingProduct = prodcts.find(
        (product) => product.Product_Id === pId
      );
      if (existingProduct) {
        const updatedQty = parseInt(existingProduct.Quantity) + parseInt(pQty);
        const updatedTotal =
          parseFloat(existingProduct.Product_Total) + parseFloat(pTotal);
        const updatedPrice = updatedTotal / updatedQty;
        existingProduct.Quantity = updatedQty.toString();
        existingProduct.Product_Total = updatedTotal.toString();
        setpPreice(updatedPrice);
      } else {
        const prodctsObj = {
          id: Math.random().toString(),
          Product_Id: pId,
          Product_Name: pName,
          Price: pPreice,
          Quantity: pQty,
          Product_Total: pTotal,
        };
        const producterror = {};
        if (!prodctsObj.Product_Name) {
          producterror.Product_Name = "Enter Product  Name";
        }
        if (!prodctsObj.Price) {
          producterror.Price = "Enter Price";
        }
        if (!prodctsObj.Quantity) {
          producterror.Quantity = "Enter Quantity ";
        }
    
        setproductError(producterror);
        prodcts.push(prodctsObj);
      }
      const totalAmount = prodcts.reduce(
        (acc, product) => acc + parseFloat(product.Product_Total),
        0
      );
      setgrossTotal(totalAmount);
      setpName("");
      setpPreice(0);
      setSelectedpName(null);
      setpQty("");
      setpTotal(0);
    }
  };

  const handleGSTChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedGST(selectedOption);
      setSelectedGSTValue(selectedOption.value);
      const grossTotalValue = parseFloat(grossTotal);
      const gstRate = parseFloat(selectedOption.value);

      if (!isNaN(grossTotalValue) && !isNaN(gstRate)) {
        const gstAmount = (grossTotalValue * gstRate) / 100;
        setGstAmountValue(gstAmount);
        const halfOfGSTAmount = gstAmount / 2;
        setSelectedSGST(halfOfGSTAmount);
        setSelectedCGST(halfOfGSTAmount);
        TotalNetAmount(grossTotalValue, discount, gstAmount);
      } else {
        setGstAmountValue(0);
      }
    } else {
      setSelectedGST(null);
      setGstAmountValue(0);
      TotalNetAmount(grossTotal, discount, 0);
    }
  };

  const TotalNetAmount = (grossTotal, discount, gstAmount) => {
    const grossTotalValue = parseFloat(grossTotal);
    const discountValue = parseFloat(discount);
    const gstAmountValue = parseFloat(gstAmount);

    if (
      !isNaN(grossTotalValue) &&
      !isNaN(discountValue) &&
      !isNaN(gstAmountValue)
    ) {
      const netTotal = grossTotalValue + gstAmountValue - discountValue;
      setNetTotalAmount(netTotal);
    } else {
      setNetTotalAmount(0);
    }
  };

  const discountAmount = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setDiscount(numericInput);
    TotalNetAmount(grossTotal, numericInput, gstAmountValue);
  };

  const OrderSubmit = (event) => {
    event.preventDefault();

    debugger;

    const updatedOrder = {
      ...Order,
      Order_No: invoiceNumber,
      Client_Id: ClientNameId,
      Client: ClientNameName,
      Mobile_No: mobileNo,
      Billing_Address: billingAddress,
      CGST: selectedCGST,
      SGST: selectedSGST,
      GST_Per: selectedGSTValue,
      GST_Amount: gstAmountValue,
      Discount: discount,
      Net_Total: nettotalAmount,
      Products: prodcts,
      Gross_Total: grossTotal,
      Payment_Status: selectedStatusValue,
      Paid: paidValue,
      Balance: balanceValue,
      Order_Status: getOrderStatus(selectedStatusValue),
      Payment_Mode: selectedPayModeValue,
      Bank: selectedBankValue,
      Remarks: null,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    function getOrderStatus(paymentStatus) {
      if (paymentStatus === "Unpaid") {
        return "Pending";
      } else if (paymentStatus === "Partial Paid") {
        return "Pending";
      } else if (paymentStatus === "Paid") {
        return "Pending";
      }
    }

    const errors = {};
   
    const mobileRegx = /^([6-9][0-9]{9,11})$/;

    // if (!Order.ClientNameId) {
    //   errors.ClientNameId = "Select client";
    // }

    // if (!Order.Order_Date) {
    //   errors.Order_Date = "Select Order Date";
    // }
    // if (!Order.Mobile_No) {
    //   errors.Mobile_No = "Enter Mobile Number";
    // } else if (!mobileRegx.test(Order.Mobile_No)) {
    //   errors.Mobile_No = "Invalid Mobile Number!";
    // }
    // if (!Order.Billing_Address) {
    //   errors.Billing_Address = "Enter Billing Address";
    // }

    // if (!Order.Discount) {
    //   errors.Discount = "Discount is required!";
    // }
    // if (!Order.Net_Total) {
    //   errors.Net_Total = "Net Total is required!";
    // }

    if (Object.keys(errors).length === 0) {
      buttonElement.current.disabled = true;
      axios
        .post(`${apiUrl}/addOrder`, updatedOrder)
        .then((res) => {
          buttonElement.current.disabled = false;
          Swal.fire("Success!", "Your Order has been Placed.", "success");
          navigate("/viewOrder");
          prodcts = [];
        })
        .catch((err) => console.error(err));
    } else {
      setFormError(errors);
    }
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedStatusValue(selectedOption.value);
      const paymentAmount = parseFloat(nettotalAmount);

      if (selectedOption.value === "Paid") {
        setPaidValue(paymentAmount);
        setBalanceValue(0);
      } else if (selectedOption.value === "Unpaid") {
        setPaidValue(0);
        setBalanceValue(paymentAmount);
      } else if (selectedOption.value === "Partial Paid") {
        setPaidValue(0);
        setBalanceValue(0);
      }
    } else {
      setSelectedStatusValue("");
    }
    setSelectedStatus(selectedOption);
  };

  const handlePaymentModeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedPayModeValue(selectedOption.value);
    } else {
      setSelectedPayModeValue("");
    }
    setSelectedPayMode(selectedOption);
  };

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
    setOrder({ ...Order, Reference_No: value });
  };

  const handleBankChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBankValue(selectedOption.value);
    } else {
      setSelectedBankValue("");
    }
    setSelectedBank(selectedOption);
  };

  const handleClientNameChange = (selectedOption) => {
    if (selectedOption) {
      setClientNameName(selectedOption.label);
      setClientNameId(selectedOption.value);
    } else {
      setClientNameName("");
      setClientNameId(null);
    }
    setSelectedClientName(selectedOption);
    setpQty("");
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
            <h4 className="page-title">Create Order Invoice</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <form onSubmit={OrderSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Order Number*</label>
                  <input
                    type="text"
                    id="invoiceNumber"
                    name="invoiceNumber"
                    disabled
                    className="form-control"
                    value={invoiceNumber}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Client Name*</label>
                  <Select
                    type="text"
                    className={` ${formError.ClientNameId ? "is-invalid" : ""}`}
                    name="ClientNameOptions"
                    options={ClientNameOptions}
                    id="ClientNameOptions"
                    required
                    value={selectedClientName}
                    onChange={handleClientNameChange}
                  />
                  {formError.ClientNameId && (
                    <div className="invalid-feedback">
                      {formError.ClientNameId}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label className="form-label">Order Date*</label>
                  <input
                    type="Date"
                    id="Order_Date"
                    name="Order_Date"
                    required
                    onChange={(e) =>
                      setOrder({
                        ...Order,
                        Order_Date: e.target.value,
                      })
                    }
                    className={`form-control ${
                      formError.Order_Date ? "is-invalid" : ""
                    }`}
                  />
                  {formError.Order_Date && (
                    <div className="invalid-feedback">
                      {formError.Order_Date}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label className="form-label">Mobile Number*</label>
                  <input
                    type="text"
                    id="Mobile_No"
                    name="Mobile_No"
                    maxLength={10}
                    value={mobileNo}
                    required
                    onChange={(e) => {
                      const input = e.target.value;
                      const numericInput = input.replace(/[^0-9]/g, "");
                      setOrder({ ...Order, Mobile_No: numericInput });
                    }}
                    className="form-control"
                    placeholder="Mobile Number"
                  />
                  <p style={{ color: "red" }}>{formError.mobile_No}</p>
                </div>
              </div>

              <div className="row mt-1 mb-3">
                <div className="col-md-12">
                  <label className="form-label">Billing Address*</label>
                  <textarea
                    type="text"
                    id="Billing_Address"
                    name="Billing_Address"
                    required
                    value={billingAddress}
                    onChange={(e) =>
                      setOrder({ ...Order, Billing_Address: e.target.value })
                    }
                    className="form-control"
                    placeholder="Enter Billing Address"
                  />
                </div>
              </div>
              <hr style={{ borderWidth: "2px" }} />
              <div className="row mt-2">
                <h4>Product Detail</h4>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label className="form-label">Product Name*</label>
                  <Select
                    id="txtProductName"
                    name="txtProductName"
                    onChange={pnameChange}
                    value={selectedpName}
                    required
                    options={pNameOption}
                    placeholder="Product Name"
                  />
                  <p style={{ color: "red" }}>{pError.Product_Name}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Price*</label>
                  <input
                    type="text"
                    id="txtPrice"
                    name="txtPrice"
                    onChange={pPreiceChange}
                    value={pPreice}
                    required
                    className="form-control"
                    placeholder="Price"
                  />
                  <p style={{ color: "red" }}>{pError.Price}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Quantity*</label>
                  <input
                    type="text"
                    id="txtQuantity"
                    name="txtQuantity"
                    onChange={pQtyChange}
                    value={pQty}
                    required
                    autoCapitalize="false"
                    className="form-control"
                    placeholder="Quantity"
                  />
                  <p style={{ color: "red" }}>{pError.Quantity}</p>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <label className="form-label">Total</label>
                  <input
                    type="text"
                    id="txtTotal"
                    name="txtTotal"
                    disabled
                    required
                    className="form-control"
                    value={pTotal}
                    defaultValue={0}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-3 mb-3">
                <div className="col-md-2 text-center">
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="btnAddProduct"
                    name="Add Product"
                    onClick={addProductClick}
                  >
                    Add Product
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {prodcts.length > 0 && (
                    <table
                      id="basic-datatable"
                      className="table table-striped dt-responsive nowrap w-100 basic-datatable"
                      style={{ display: "table" }}
                    >
                      <thead style={{ width: "100% !important" }}>
                        <tr>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <AddOrderProduct productList={prodcts} />
                    </table>
                  )}
                </div>
              </div>
              <hr style={{ borderWidth: "2px" }} />
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Gross Total*</label>
                  <input
                    type="text"
                    id="txtGrossTotal"
                    disabled
                    required
                    value={grossTotal}
                    className="form-control"
                    placeholder="Gross Total"
                    defaultValue={0}
                  />
                  <p style={{ color: "red" }}>{formError.Gross_Total}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">GST%*</label>
                  <Select
                    id="txtGrossTotal"
                    options={GST}
                    required
                    value={selectedGST}
                    onChange={handleGSTChange}
                    placeholder="Select Gst Rate"
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">GST Amount*</label>
                  <input
                    type="text"
                    required
                    id="txtgstTotal"
                    value={gstAmountValue}
                    className="form-control"
                    defaultValue={0}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Discount*</label>
                  <input
                    type="text"
                    id="txtDiscount"
                    value={discount}
                    onChange={discountAmount}
                    className="form-control"
                    placeholder="Discount"
                    defaultValue={0}
                    required
                  />
                
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Payment_Status" className="form-label">
                      Status*
                    </label>
                    <Select
                      type="text"
                      name="Payment_Status"
                      id="Payment_Status"
                      options={Status}
                      required
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Net Total*</label>
                  <input
                    type="text"
                    id="txtNetTotal"
                    value={nettotalAmount}
                    required
                    onChange={TotalNetAmount}
                    className="form-control"
                    defaultValue={0}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Paid" className="form-label">
                      Paid Amount*
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="Paid"
                      name="Paid"
                      value={paidValue}
                      placeholder="Enter Paid Amount"
                      defaultValue={0}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.match(/^\d*\.?\d*/)[0];
                        const updatedPaid = parseFloat(numericInput);
                        const updatedBalance = nettotalAmount - updatedPaid;
                        setPaidValue(numericInput);
                        setBalanceValue(updatedBalance);
                      }}
                      readOnly={selectedStatusValue !== "Partial Paid"}
                      disabled={selectedStatusValue !== "Partial Paid"}
                      required={selectedStatusValue === "Partial Paid"}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Balance" className="form-label">
                      Balance Amount*
                    </label>
                    <input
                      type="text"
                      name="Balance"
                      className="form-control"
                      id="Balance"
                      readOnly
                      disabled
                      value={balanceValue}
                      defaultValue={0}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {(selectedStatusValue === "Paid" ||
                  selectedStatusValue === "Partial Paid") && (
                  <>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Payment_Mode" className="form-label">
                          Payment Mode*
                        </label>
                        <Select
                          type="text"
                          name="Payment_Mode"
                          id="Payment_Mode"
                          options={Payment_Mode}
                          value={selectedPayMode}
                          onChange={handlePaymentModeChange}
                          required={
                            selectedStatusValue === "Paid" ||
                            selectedStatusValue === "Partial Paid"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Payment_Date" className="form-label">
                          Payment Date*
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="Payment_Date"
                          name="Payment_Date"
                          required={
                            selectedStatusValue === "Paid" ||
                            selectedStatusValue === "Partial Paid"
                          }
                          onChange={(e) =>
                            setOrder({
                              ...Order,
                              Payment_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {selectedPayModeValue === "Cheque" && (
                  <>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Bank" className="form-label">
                          Bank*
                        </label>
                        <Select
                          type="text"
                          name="Bank"
                          id="Bank"
                          options={Bank}
                          value={selectedBank}
                          onChange={handleBankChange}
                          required={selectedPayModeValue === "Cheque"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Cheque_No" className="form-label">
                          Cheque No*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="Cheque_No"
                          name="Cheque_No"
                          placeholder="Enter Cheque No"
                          value={Order.Cheque_No}
                          required={selectedPayModeValue === "Cheque"}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setOrder({
                              ...Order,
                              Cheque_No: numericInput,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Cheque_Date" className="form-label">
                          Cheque Date*
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="Cheque_Date"
                          name="Cheque_Date"
                          required={selectedPayModeValue === "Cheque"}
                          onChange={(e) =>
                            setOrder({
                              ...Order,
                              Cheque_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {selectedPayModeValue === "Online" && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Reference_No" className="form-label">
                        Transaction ID*
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Reference_No"
                        name="Reference_No"
                        required={selectedPayModeValue === "Online"}
                        placeholder="Enter Transaction ID"
                        value={inputValue}
                        onInput={handleInput}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-md-2 text-center">
                  {prodcts.length > 0 && (
                    <button
                      className="btn btn-primary"
                      ref={buttonElement}
                      type="submit"
                      id="btnSubmit"
                      style={{ display: "block" }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateOrder;
