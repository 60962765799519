import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "react-data-table-component";
import SummaryModal from "./Modal";
import "../../CSS/datatable.css";
import "datatables.net";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Success, Confirmation } from "../Alerts/alert";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import IconButton from "@mui/material/IconButton";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import { Reload } from "mdi-material-ui";

const ViewProforma = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setPorfomaData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState({});
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [uniquePStatus, setUniquePStatus] = useState([]);
  const [uniqueOStatus, setUniqueOStatus] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    (async () => {
      const result = await Load();
      setPorfomaData(result.data);
      setIsDataLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      debugger;
      $("#basic-datatable").DataTable({
        columnDefs: [
          {
            targets: -1, // -1 refers to the last column
            orderable: false, // Disable sorting
          },
        ],
      }); // Initialize DataTables
    }
  }, [isDataLoaded]);

  async function Load() {
    showLoader();
    const result = await axios.get(`${apiUrl}/getOrders`);
    const Pstatus = Array.from(
      new Set(result.data.map((row) => row.Payment_Status))
    );
    const Ostatus = Array.from(
      new Set(result.data.map((row) => row.Order_Status))
    );
    setUniquePStatus(Pstatus);
    setUniqueOStatus(Ostatus);
    setRecords(result.data);
    hideLoader();
    setPorfomaData(result.data);
    return result;
  }

  const handleDelete = async (Id) => {
    const confirmed = await Confirmation("You Want to Delete this Record?");

    if (confirmed) {
      try {
        await axios.delete(`${apiUrl}/deleteOrder/${Id}`);
        Success("Order record deleted successfully.");
        Load();
        setIsDataLoaded(true);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    }
  };

  const handleDateRangeFilter = () => {
    const filteredData = data.filter((row) => {
      const billDate = new Date(row.Order_Date);
      billDate.setHours(0, 0, 0, 0);
      if (fromDate && toDate) {
        return billDate >= fromDate && billDate <= toDate;
      } else if (fromDate) {
        return billDate >= fromDate;
      } else if (toDate) {
        return billDate <= toDate;
      }
      return true;
    });
    setRecords(filteredData);
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Order_No && row.Order_No.toLowerCase().includes(filterValue)) ||
        (row.Client && row.Client.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Order_Date) &&
          formatDate(row.Order_Date).toLowerCase().includes(filterValue)) ||
        (row.Payment_Status &&
          row.Payment_Status.toLowerCase().includes(filterValue)) ||
        (row.Order_Status &&
          row.Order_Status.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Order Invoice Records", 20, 10);

    const columns = [
      "Order No",
      " Order Date",
      " Payment Status",
      " Client",
      " Net Total",
      " Paid",
      " Balance",
      " Order Status",
    ];

    const rows = displayData.map((row) => [
      row.Order_No,
      formatDate(row.Order_Date),
      row.Client,
      row.Payment_Status,
      row.Net_Total,
      row.Paid,
      row.Balance,
      row.Order_Status,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Order-Invoice-Data.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Order No",
      " Order Date",
      " Payment Status",
      " Client",
      " Net Total",
      " Paid",
      " Balance",
      " Order Status",
    ];

    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        row.Order_No,
        formatDate(row.Order_Date),
        row.Client,
        row.Payment_Status,
        row.Net_Total,
        row.Paid,
        row.Balance,
        row.Order_Status,
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Order-Invoice-Data");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Order-Invoice-Data.xlsx");
  };

  const handleOpenSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = true;
    setIsSummaryModalOpen(newModalState);
  };

  const handleCloseSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = false;
    setIsSummaryModalOpen(newModalState);
    window.location.reload();
  };

  const columns = [
    {
      name: "Order No",
      selector: (row) => row.Order_No,
      width: "120px",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => formatDate(row.Order_Date),
      width: "120px",
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row.Client,
      width: "200px",
      sortable: true,
    },

    {
      name: "P-Status",
      selector: (row) => row.Payment_Status,

      sortable: true,
      width: "100px",
    },
    {
      name: "Net Total",
      selector: (row) => row.Net_Total,

      sortable: true,
      width: "100px",
    },
    {
      name: "Paid",
      selector: (row) => row.Paid,
      sortable: true,
      width: "90px",
    },
    {
      name: "Bal",
      selector: (row) => row.Balance,
      sortable: true,
      width: "90px",
    },
    {
      name: "O-Status",
      selector: (row) => row.Order_Status,
      sortable: true,
      width: "100px",
    },
    {
      cell: (row) => (
        <div className="text-center">
          <button
            type="button"
            className="btn btn-transform shadow-none"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "0px", border: 0 }}
          >
            <i className="ri-more-2-fill"></i>
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                onClick={() => {
                  handleOpenSummaryModal(row.Sr_No);
                }}
                className="dropdown-item"
              >
                <i
                  className="ri-file-chart-fill"
                  style={{
                    color: "grey",
                    fontSize: "16px",
                  }}
                ></i>
                <span> Summary</span>
              </Link>
            </li>
            <SummaryModal
              open={isSummaryModalOpen[row.Sr_No] || false}
              onClose={() => {
                handleCloseSummaryModal(row.Sr_No);
              }}
              Sr_No={row.Sr_No}
            />

            <li>
              <Link to={`/invoice/${row.Sr_No}`} className="dropdown-item">
                <i
                  className="ri-printer-fill"
                  style={{
                    color: "grey",
                    fontSize: "16px",
                  }}
                ></i>
                <span> Print Invoice</span>
              </Link>
            </li>
            <li>
              <a
                onClick={() => handleDelete(row.Sr_No)}
                className="dropdown-item"
                href="#"
              >
                <i
                  className="ri-delete-bin-3-line"
                  style={{
                    color: "red",
                    fontSize: "16px",
                  }}
                ></i>
                <span> Delete</span>
              </a>
            </li>
          </ul>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const filterPData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Payment_Status === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]);
    }
  };

  const filterOData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Order_Status === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]);
    }
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
              <h4 className="page-title">View Order Invoice</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="column">
                  <div className="col-md-2 mb-2">
                    <label className="form-label">Filter By Inv Date:</label>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex justify-content-start">
                        <div style={{ marginRight: "10px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="From Date"
                              value={fromDate}
                              onChange={(date) => setFromDate(date)}
                              slotProps={{ textField: { size: "small" } }}
                              sx={{ width: "150px" }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="To Date"
                              value={toDate}
                              onChange={(date) => setToDate(date)}
                              slotProps={{ textField: { size: "small" } }}
                              sx={{ width: "150px" }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div style={{ marginRight: "10px" }}>
                        <Button
                          variant="contained"
                          onClick={handleDateRangeFilter}
                        >
                          Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mb-2 ml-1">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={uniquePStatus}
                    sx={{ width: 180 }}
                    onChange={(e, v) => filterPData(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filter By P-Status"
                      />
                    )}
                  />
                </div>
                <div className="d-flex justify-content-end mb-2 ml-1">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={uniqueOStatus}
                    sx={{ width: 180 }}
                    onChange={(e, v) => filterOData(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filter By O-Status"
                      />
                    )}
                  />
                </div>

                <div className="d-flex justify-content-end mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                  <Link to={`/order`}>
                    <Button variant="contained" endIcon={<AddCircleIcon />}>
                      Add
                    </Button>
                  </Link>
                </div>

                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={displayData}
                    defaultSortField="Invoice_Date"
                    fixedHeader
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default ViewProforma;
