import React from 'react'

const OrderTable = (props) => {
    return (
        <tr>
            <td>{props.Product_Name}</td>
            <td>{props.Price}</td>
            <td>{props.Quantity}</td>
            <td>{props.Product_Total}</td>
        </tr>
    )
}
export default OrderTable;