import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import { useUser } from "../../componets/UserContext";
import { apiUrl } from "../../componets/apiConfig";
import useButtonLoader from "../../mui/useButtonLoader";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const Addvendor = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { userData } = useUser();
  const [buttonElement, setLoading] = useButtonLoader(
    "Add Vendor",
    "Adding..."
  );

  const [vendor, setvendor] = useState({
    Org_Name: "",
    Org_Mobile: "",
    SupplierInfo: [{ Supplier_Name: "", Supplier_Mobile: "" }],
    Address: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const [supplierErrors, setSupplierErrors] = useState([{}]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedvendor = {
      ...vendor,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const validationErrors = {};
    // Validate the Supplier_Mobile fields
    const updatedSupplierErrors = vendor.SupplierInfo.map((supplier) => {
      const errors = {};

      if (!/^([6-9][0-9]{9,11})$/.test(supplier.Supplier_Mobile)) {
        errors.Supplier_Mobile = "Invalid Mobile Number";
      }

      return errors;
    });

    setSupplierErrors(updatedSupplierErrors);

    if (
      updatedSupplierErrors.some((errors) => Object.keys(errors).length > 0)
    ) {
      return;
    }

    if (!/^([6-9][0-9]{9,11})$/.test(vendor.Org_Mobile)) {
      validationErrors.Org_Mobile = "Invalid Mobile Number";
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/addVendor`, updatedvendor)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Your Vendor Record has been Added.",
            "success"
          );
          navigate("/viewVender");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(validationErrors);
    }
  };

  const handleAddSupplier = () => {
    // Clone the current SupplierInfo array and add a new object
    const updatedSupplierInfo = [
      ...vendor.SupplierInfo,
      { Supplier_Name: "", Supplier_Mobile: "" },
    ];

    // Update the state with the new array
    setvendor({ ...vendor, SupplierInfo: updatedSupplierInfo });

    // Also add an empty error object for the new supplier
    setSupplierErrors([...supplierErrors, {}]);
  };

  const handleSupplierInfoChange = (index, field, value) => {
    // Clone the current SupplierInfo array
    const updatedSupplierInfo = [...vendor.SupplierInfo];

    // Update the specified field of the supplier at the given index
    updatedSupplierInfo[index][field] = value;

    // Update the state with the new array
    setvendor({ ...vendor, SupplierInfo: updatedSupplierInfo });
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Add Vendor</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Org_Name" className="form-label">
                      Organization Name*
                    </label>
                    <input
                      type="text"
                      id="Org_Name"
                      name="Org_Name"
                      required
                      onChange={(e) =>
                        setvendor({ ...vendor, Org_Name: e.target.value })
                      }
                      className="form-control"
                      placeholder="Enter Organization Name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Org_Mobile" className="form-label">
                      Organization Mobile*
                    </label>
                    <input
                      type="text"
                      name="Org_Mobile"
                      className={`form-control ${
                        errors.Org_Mobile ? "is-invalid" : ""
                      }`}
                      id="Org_Mobile"
                      required
                      maxLength="10"
                      value={vendor.Org_Mobile}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setvendor({ ...vendor, Org_Mobile: numericInput });
                      }}
                      placeholder="Enter Organization Mobile Number"
                    />
                    {errors.Org_Mobile && (
                      <div className="invalid-feedback">
                        {errors.Org_Mobile}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {vendor.SupplierInfo.map((supplier, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor={`Supplier_Name_${index}`}
                          className="form-label"
                        >
                          Supplier Name*
                        </label>
                        <input
                          type="text"
                          id={`Supplier_Name_${index}`}
                          name={`Supplier_Name_${index}`}
                          required
                          value={supplier.Supplier_Name}
                          onChange={(e) =>
                            handleSupplierInfoChange(
                              index,
                              "Supplier_Name",
                              e.target.value
                            )
                          }
                          className="form-control"
                          placeholder="Enter Supplier Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor={`Supplier_Mobile_${index}`}
                          className="form-label"
                        >
                          Supplier Mobile*
                        </label>
                        <input
                          type="text"
                          id={`Supplier_Mobile_${index}`}
                          name={`Supplier_Mobile_${index}`}
                          className={`form-control ${
                            supplierErrors[index]?.Supplier_Mobile
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                          value={supplier.Supplier_Mobile}
                          onChange={(e) =>
                            handleSupplierInfoChange(
                              index,
                              "Supplier_Mobile",
                              e.target.value
                            )
                          }
                          maxLength="10"
                          placeholder="Enter Supplier Mobile Number"
                        />
                        {supplierErrors[index]?.Supplier_Mobile && (
                          <div className="invalid-feedback">
                            {supplierErrors[index].Supplier_Mobile}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="col-md-4">
                    <Tooltip title="Add More Supplier">
                      <AddCircleOutlineIcon
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "-50px",
                        }}
                        onClick={handleAddSupplier}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="Address" className="form-label">
                      Address*
                    </label>
                    <textarea
                      className="form-control"
                      id="Address"
                      name="Address"
                      placeholder="Address"
                      required
                      onChange={(e) =>
                        setvendor({ ...vendor, Address: e.target.value })
                      }
                      multiline="true"
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    ref={buttonElement}
                  >
                    Add Vendor
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addvendor;
