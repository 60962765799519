import React from "react";
import Select from "react-select";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../componets/apiConfig";
import useButtonLoader from "../../mui/useButtonLoader";
import { useState, useEffect } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";

const Type = [
  { label: "Old", value: "Old" },
  { label: "New", value: "New" },
];

const AddVisit = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [buttonElement, setLoading] = useButtonLoader(
    "Create Visit",
    "Create Visit..."
  );
  const [errors, setErrors] = useState({});
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedSalesPerson, setselectedSalesPerson] = useState([]);
  const [selectedClientOrgName, setselectedClientOrgName] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedTypeValue, setSelectedTypeValue] = useState("");
  const [salesPersonOption, setsalesPersonOption] = useState([]);
  const [clientOrgNameOption, setclientOrgNameOption] = useState([]);
  const [salesPersonName, setsalesPersonName] = useState("");
  const [salesPersonId, setsalesPersonId] = useState(null);
  const [clientOrgValue, setclientOrgValue] = useState("");
  const [clientOrgId, setclientOrgId] = useState(null);

  useEffect(() => {
    loadStaffData();
  }, []);

  const loadStaffData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getSalesPersons`);
      const StaffDate = response.data;
      const salesPerson = StaffDate.map((staff) => ({
        label: `${staff.First_Name} ${staff.Last_Name}`,
        value: staff.Id,
      }));
      setsalesPersonOption(salesPerson);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    loadClientData();
  }, []);

  const loadClientData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getClient`);
      const ClientData = response.data;
      const orgName = ClientData.map((client) => ({
        label: client.Company_Name,
        value: client.Sr_No,
      }));

      setclientOrgNameOption(orgName);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };

  const [client, setClient] = useState({});
  useEffect(() => {
    if (clientOrgId) {
      axios.get(`${apiUrl}/getClient/${clientOrgId}`).then((res) => {
        setClient(res.data);
      });
    }
  }, [clientOrgId]);

  const [visit, setVisit] = useState({
    Sales_Person_Id: "",
    Sales_Person: "",
    Org_Type: "",
    Org_Id: "",
    Org_Name: "",
    Person_Name: "",
    Visit_Date: "",
    Visit_Time: "",
    Remark: "",
    Mobile: "",
    Pin_Code: "",
    Address: "",
    Status: "",
    Added_By_Id: "",
    Added_By_Name: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    setLoading(true);

    const updatedVisit = {
      ...visit,
      Sales_Person_Id: salesPersonId,
      Sales_Person: salesPersonName,
      Org_Id: selectedTypeValue === "Old" ? clientOrgId : 0,
      Org_Name: selectedTypeValue === "Old" ? clientOrgValue : visit.Org_Name,
      Person_Name:
        selectedTypeValue === "Old" ? client.Owner_Name : visit.Person_Name,
      Mobile: selectedTypeValue === "Old" ? client.Mobile_No : visit.Mobile,
      Pin_Code: selectedTypeValue === "Old" ? client.PinCode : visit.Pin_Code,
      Address: selectedTypeValue === "Old" ? client.Address : visit.Address,
      Org_Type: selectedTypeValue,
      Status: "Pending",
      Added_By_Id: userData.userId,
      Added_By_Name: `${userData.firstName} ${userData.lastName}`,
    };

    console.log(visit.Visit_Date);
    console.log(visit.Visit_Time);

    const error = {};
    if (!/^([6-9][0-9]{9,11})$/.test(visit.Mobile)) {
      error.Mobile = "Invalid Mobile Number";
    }

    if (Object.keys(errors).length === 0) {
      axios
        .post(`${apiUrl}/addVisit`, updatedVisit)
        .then((res) => {
          navigate("/viewVisit");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(error);
    }
  };

  const handleTypeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedTypeValue(selectedOption.value);
    } else {
      setSelectedTypeValue("");
    }
    setSelectedType(selectedOption);
  };

  const handleSalesPersonChange = (selectedOption) => {
    if (selectedOption) {
      setsalesPersonName(selectedOption.label);
      setsalesPersonId(selectedOption.value);
    } else {
      setsalesPersonName("");
      setsalesPersonId(null);
    }
    setselectedSalesPerson(selectedOption);
  };

  const handleOrgNameChange = (selectedOption) => {
    if (selectedOption) {
      setclientOrgValue(selectedOption.label);
      setclientOrgId(selectedOption.value);
    } else {
      setclientOrgValue("");
      setclientOrgId(null);
    }
    setselectedClientOrgName(selectedOption);
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">Add Visit</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="salesPerson" className="form-label">
                      Sales Person*
                    </label>
                    <Select
                      type="text"
                      name="salesPerson"
                      required
                      id="salesPerson"
                      value={selectedSalesPerson}
                      options={salesPersonOption}
                      onChange={handleSalesPersonChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Visit_Date" className="form-label">
                      Visit Date
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="Visit_Date"
                      id="Visit_Date"
                      onChange={(e) =>
                        setVisit({
                          ...visit,
                          Visit_Date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Visit_Time" className="form-label">
                      Visit Time
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="Visit_Time"
                      id="Visit_Time"
                      onChange={(e) =>
                        setVisit({
                          ...visit,
                          Visit_Time: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="selectedType" className="form-label">
                      Org Type*
                    </label>
                    <Select
                      type="text"
                      name="selectedType"
                      required
                      id="selectedType"
                      options={Type}
                      value={selectedType}
                      onChange={handleTypeChange}
                    />
                  </div>
                </div>
              </div>
              {selectedTypeValue === "Old" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="clientOrgName" className="form-label">
                        Client*
                      </label>
                      <Select
                        type="text"
                        name="clientOrgName"
                        required
                        id="clientOrgName"
                        value={selectedClientOrgName}
                        options={clientOrgNameOption}
                        onChange={handleOrgNameChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedTypeValue === "New" && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Client_Id" className="form-label">
                          Org Name*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="Org_Name"
                          required
                          id="Org_Name"
                          onChange={(e) =>
                            setVisit({
                              ...visit,
                              Org_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Person_Name" className="form-label">
                          Person Name*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="Person_Name"
                          required
                          id="Person_Name"
                          onChange={(e) =>
                            setVisit({
                              ...visit,
                              Person_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Mobile" className="form-label">
                          Mobile*
                        </label>
                        <input
                          className={`form-control ${
                            errors.Mobile ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="Mobile"
                          required
                          id="Mobile"
                          maxLength="10"
                          value={visit.Mobile}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setVisit({
                              ...visit,
                              Mobile: numericInput,
                            });
                          }}
                        />
                        {errors.Mobile && (
                          <div className="invalid-feedback">
                            {errors.Mobile}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Pin_Code" className="form-label">
                          Pin Code*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="Pin_Code"
                          required
                          id="Pin_Code"
                          maxLength="6"
                          value={visit.Pin_Code}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setVisit({
                              ...visit,
                              Pin_Code: numericInput,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <label htmlFor="Address" className="form-label">
                        Address*
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="Address"
                        id="Address"
                        placeholder="Address"
                        onChange={(e) =>
                          setVisit({
                            ...visit,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="Client_Id" className="form-label">
                    Additional Remark
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="Client_Id"
                    id="Client_Id"
                    placeholder="Additional Remark..."
                    onChange={(e) =>
                      setVisit({
                        ...visit,
                        Remark: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    ref={buttonElement}
                  >
                    Create Visit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default AddVisit;
