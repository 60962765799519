import * as React from "react";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import ChangePasswordModal from "./EditBill";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import Autocomplete from "@mui/material/Autocomplete";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";


const ExpensesDataTable = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const columns = [
    {
      name: "Bill No",
      selector: (row) => row.Bill_No,
      sortable: true,
    },
    {
      name: "Bill Date",
      selector: (row) => formatDate(row.Bill_Date),
      sortable: true,
    },
    {
      name: " Category",
      selector: (row) => row.Category,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Payment_Status,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.Amount,
      sortable: true,
    },
    {
      name: " Paid",
      selector: (row) => row.Paid,
      sortable: true,
    },
    {
      name: "  Balance",
      selector: (row) => row.Balance,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Sr_No)}
            aria-controls="row-menu"
            aria-haspopup="true"
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleOpenChangePasswordModal(selectedRowSrNo);
              }}
            >
              Summary
            </MenuItem>
            <ChangePasswordModal
              open={isChangePasswordModalOpen}
              onClose={handleCloseChangePasswordModal}
              Sr_No={selectedRowSrNo}
            />
            <MenuItem
              onClick={() => {
                handleMenuClose();
                deleteUser(selectedRowSrNo);
              }}
              style={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    loadExpensesData();
  }, []);

  const loadExpensesData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getExpensesMaster7`);
      const status = Array.from(
        new Set(response.data.map((row) => row.Payment_Status))
      );
      setUniqueStatus(status);
      setData(response.data);
      setRecords(response.data);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  const handleMenuClick = (event, Sr_No) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(Sr_No);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    loadExpensesData();
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteExpense/${Sr_No}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    loadExpensesData();
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };


  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Bill_No && row.Bill_No.toLowerCase().includes(filterValue)) ||
        (row.Bill_Date && row.Bill_Date.toLowerCase().includes(filterValue)) ||
        (row.Category && row.Category.toLowerCase().includes(filterValue)) ||
        (row.Payment_Status &&
          row.Payment_Status.toLowerCase().includes(filterValue)) ||
        (row.Supplier && row.Supplier.toLowerCase().includes(filterValue)) ||
        (row.Title && row.Title.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  };
  
  const handleDateRangeFilter = () => {
    const filteredData = data.filter((row) => {
      const billDate = new Date(row.Bill_Date);
      billDate.setHours(0, 0, 0, 0);
      if (fromDate && toDate) {
        return billDate >= fromDate && billDate <= toDate;
      } else if (fromDate) {
        return billDate >= fromDate;
      } else if (toDate) {
        return billDate <= toDate;
      }
      return true;
    });
    setRecords(filteredData);
  };
  
  

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Expenses", 20, 10);

    const columns = [
      "Bill No",
      "Bill Date",
      "Category",
      "Supplier",
      "Title",
      "Payment Status",
      "Total",
      "Paid",
      "Balance",
    ];

    const rows = displayData.map((row) => [
      row.Bill_No,
      formatDate(row.Bill_Date),
      row.Category,
      row.Supplier,
      row.Title,
      row.Payment_Status,
      row.Amount,
      row.Paid,
      row.Balance,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Expenses.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Bill No",
      "Bill Date",
      "Category",
      "Supplier",
      "Title",
      "Payment Status",
      "Total",
      "Paid",
      "Balance",
    ];

    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        row.Bill_No,
        formatDate(row.Bill_Date),
        row.Category,
        row.Supplier,
        row.Title,
        row.Payment_Status,
        row.Amount,
        row.Paid,
        row.Balance,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Expenses");

    // Buffer
   // let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Expenses.xlsx");
  };

  const filterData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Payment_Status === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]); 
    }
  };

  return (
    <div>
      <>
        <Box height={10} />
        <div className="d-flex justify-content-end mb-2 ml-1">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={uniqueStatus}
                    sx={{ width: 180 }}
                    onChange={(e, v) => filterData(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filter By Status"
                      />
                    )}
                  />
                </div>
        <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
          <TextField onChange={handleFilter} size="small" label="Search..." />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            slotProps={{ textField: { size: "small" } }}
            sx={{ width: '150px' }}
            renderInput={(params) => <TextField {...params} />}
          />
           </LocalizationProvider>

           <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            slotProps={{ textField: { size: "small" } }}
            sx={{ width: '150px' }}
            renderInput={(params) => <TextField {...params} />}
          />
           </LocalizationProvider>

          <Button variant="contained" style={{}} onClick={handleDateRangeFilter}>
            Filter
          </Button>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Tooltip title="Export to PDF">
            <IconButton
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                DownloadPDF();
              }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Export to Excel">
            <IconButton
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                DownloadExcel();
              }}
            >
              {" "}
              <Icon path={mdiFileExcel} size={1} />
            </IconButton>
          </Tooltip>
          <Link to={"/createBill"}>
            <Button variant="contained" endIcon={<AddCircleIcon />}>
              Add
            </Button>
          </Link>
        </Stack>
        <Box height={10} />

        <DataTable columns={columns} data={displayData} fixedHeader pagination />
      </>
      {loader}
    </div>
  );
};

export default ExpensesDataTable;
