import * as React from "react";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import ChangePasswordModal from "./EditVisit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";

const ViewVisit = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const columns = [
    {
      name: "Visit Date",
      selector: (row) => formatDate(row.Visit_Date),
      sortable: true,
    },
    {
      name: "Visit Time",
      selector: (row) => formatTime(row.Visit_Time),
      sortable: true,
    },
    {
      name: "Assigned To",
      selector: (row) => row.Sales_Person,
      sortable: true,
    },
    {
      name: " Client",
      selector: (row) => row.Org_Name,
      sortable: true,
      width: "180px",
    },

    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.Org_Type,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.id)}
            aria-controls="row-menu"
            aria-haspopup="true"
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleOpenChangePasswordModal(selectedRowSrNo);
              }}
            >
              Update Status
            </MenuItem>
            <ChangePasswordModal
              open={isChangePasswordModalOpen}
              onClose={handleCloseChangePasswordModal}
              id={selectedRowSrNo}
            />
            <MenuItem
              onClick={() => {
                handleMenuClose();
                deleteUser(selectedRowSrNo);
              }}
              style={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    loadExpensesData();
  }, []);

  const loadExpensesData = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getVisits`);
      const status = Array.from(
        new Set(response.data.map((row) => row.Status))
      );
      setUniqueStatus(status);
      setData(response.data);
      setRecords(response.data);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };


  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(id);
      }
    });
  };

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(id);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    loadExpensesData();
  };

  async function handleDelete(id) {
    await axios.delete(`${apiUrl}/deleteVisit/${id}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    loadExpensesData();
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  function formatTime(timeString) {
    const time = new Date(timeString);
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (formatDate(row.Visit_Date) &&
          formatDate(row.Visit_Date).toLowerCase().includes(filterValue)) ||
        (row.Sales_Person &&
          row.Sales_Person.toLowerCase().includes(filterValue)) ||
        (row.Org_Name && row.Org_Name.toLowerCase().includes(filterValue)) ||
        (row.Org_Name && row.Org_Name.toLowerCase().includes(filterValue)) ||
        (row.Status && row.Status.toLowerCase().includes(filterValue)) ||
        (row.Org_Type && row.Org_Type.toLowerCase().includes(filterValue))
        ||
        (formatTime(row.Visit_Time) && formatTime(row.Visit_Time).toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Visit Details", 20, 10);

    const columns = [
      "Visit Date",
      "Visit Time",
      "Assigned To",
      "Client",
      "Status",
      "Type",
    ];

    const rows = displayData.map((row) => [
      formatDate(row.Visit_Date),
      formatTime(row.Visit_Time),
      row.Sales_Person,
      row.Org_Name,
      row.Status,
      row.Org_Type,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Visit Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Visit Date",
      "Visit Time",
      "Assigned To",
      "Client",
      "Status",
      "Type",
    ];

    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        formatDate(row.Visit_Date),
        formatTime(row.Visit_Time),
        row.Sales_Person,
        row.Org_Name,
        row.Status,
        row.Org_Type,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Visit Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Visit Details.xlsx");
  };

  const filterData = (selectedMonth) => {
    if (selectedMonth) {
      const filteredData = data.filter(
        (row) => row.Status === selectedMonth
      );
      setFilteredRecords(filteredData);
    } else {
      setFilteredRecords([]); 
    }
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">View visit</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <div>
              <>
                <Box height={10} />
                <div className="d-flex justify-content-end mb-2 ml-1">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={uniqueStatus}
                    sx={{ width: 180 }}
                    onChange={(e, v) => filterData(v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filter By Status"
                      />
                    )}
                  />
                </div>
                <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                  <Link to={"/addVisit"}>
                    <Button variant="contained" endIcon={<AddCircleIcon />}>
                      Add
                    </Button>
                  </Link>
                </Stack>
                <Box height={10} />

                <DataTable
                  columns={columns}
                  data={displayData}
                  fixedHeader
                  pagination
                />
              </>
              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVisit;
