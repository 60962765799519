import React from 'react'
import OrderTableTd from './OrderTable'
const AddorderProduct = (props) => {
  return (
    <tbody>{
      props.productList.map(
        productDetail => (
              <OrderTableTd
                  key={productDetail.id}
                  Product_Name={productDetail.Product_Name}
                  Price={productDetail.Price}
                  Quantity={productDetail.Quantity}
                  Product_Total={productDetail.Product_Total}>
              </OrderTableTd>
          )
      )
  }</tbody>
  )
}
export default AddorderProduct;