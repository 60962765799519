import React from 'react';

const Biometric = () => {
    return (
        <div>
             <hr /> 
        </div>
    );
};

export default Biometric;